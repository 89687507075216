import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';

import { Navbar, VideoDetail, Login, SignUp, Onboarding, YourProtfolio, NewCampaignEcommerce, LeftNavBar, Campaigns, Statistics, CompanyHome, AgencyHome, UpBar, NewCampaignEcommerceFinal, Account} from "./components";
import DesignProfile from "./components/DesignProfile";

import { createContext, useContext } from "react";
import { BrandProvider } from './contexts/BrandContext'; // Import the AccountProvider

const UserContext = createContext();

const App = () => {

  const [userDetails, setUserDetails] = React.useState({}); // State to hold the username

  return (
    <BrowserRouter>
      <UserContext.Provider value={{ userDetails, setUserDetails }}>
        <BrandProvider> {/* Wrap the Routes with BrandProvider */}
          <Box sx={{ backgroundColor: 'white' }}>
            <Routes>
              {/* Define your routes here */}
              <Route path="/logIn" element={<Login />} /> 
              <Route path="/SignUp" element={<SignUp />} /> 
              <Route path="/Onboarding" element={<Onboarding />} /> 
              <Route path="/YourProtfolio" element={<YourProtfolio />} /> 
              <Route path="/NewCampaignEcommerce" element={<NewCampaignEcommerce />} /> 
              <Route path="/NewCampaignEcommerceFinal" element={<NewCampaignEcommerceFinal />} /> 
              <Route path="/Campaigns" element={<Campaigns />} /> 
              <Route path="/Statistics" element={<Statistics />} /> 
              <Route path="/CompanyHome" element={<CompanyHome />} /> 
              <Route path="/AgencyHome" element={<AgencyHome />} /> 
              <Route path="/DesignProfile" element={<DesignProfile />} /> 
              <Route path="/Account" element={<Account />} /> 
            </Routes>
          </Box>
        </BrandProvider>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export const useUserContext = () => {
  return useContext(UserContext);
}

export default App;
