import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { Grid, Card, CardContent } from '@mui/material'; // Import Card and CardContent from Material-UI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputBase from '@mui/material/InputBase';
import LinearProgress, {  } from '@mui/material/LinearProgress';
import LoginIcon from '@mui/icons-material/Login';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

import Fab from '@mui/material/Fab';
import CakeIcon from '@mui/icons-material/Cake';
import Chart from 'react-apexcharts'

import UpBar from './UpBar'
import SecondUpBar from './SecondUpBar'
import LeftNavBar from './LeftNavBar'

import './styles.css'; // Create this file and import it
import CompanyCard from './CompanyCard';
import { AppBar } from '@mui/material';

const pinkColor = "#cd2bff";

  const options = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: ['Column 1', 'Column 2', 'Column 3'],
    },
    colors: ['#FF69B4', '#FF69B4'], // Set the color to pink (#FF69B4)

  };

  const series = [
    {
      name: 'Series 1',
      data: [90, 50, 20], // Data for the two columns
    },
  ];






  const chartData = {
    options: {
      chart: {
        id: 'spline-chart',
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        
      },
    },
    series: [
      {
        name: 'Series 1',
        data: [30, 40, 25, 50, 49, 21, 70, 51, 60, 80, 90, 91],
        colors: ['#FF6B4'], // Set the color to pink

      },
    ],
    stroke: {
      curve: 'smooth'
    },


  };

const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'

  };

  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };


  const customTextStyleTitle = {
    fontFamily: 'Quicksand, serif',
    fontSize: '18px', // Replace with your font size
    fontWeight: 'bold', // Replace with your font weight
    // Add any other styling properties you need
  };


  const customTextStyleNumber = {
    fontFamily: 'Quicksand, serif',
    fontSize: {
      xs: '70px', // Set a smaller font size for xs devices
      // Add other breakpoints as needed
      sm: '70px',
      md: '80px',
      // lg: 'some_value',
      // xl: 'some_value',
    },
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#26babd',
  };

const Statistics = () => {

    return (

      <div style={{ display: 'flex', margin: 0, padding: 10, background: 'radial-gradient(circle, #e0f7ff, #7ddfff, #007da8 )'}}>

            
      <Box sx={{width:"100%"}}>


              <Box sx={{ width: '100%' }}>
                  <UpBar>ggg</UpBar>
              </Box>

              <Box>
                  <SecondUpBar></SecondUpBar>
              </Box>

              <Typography variant="h3" sx={{ color: '#d90d7d', fontFamily: 'Luckiest Guy, serif', mb: 10, mt:5,  ml:2, mr:2, display: 'flex', flexDirection: 'column', alignItems: 'center',   textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>
                  Your statistics
              </Typography>


                <Box sx={{ width: '100%', mt: 5, display: 'flex', alignItems: 'center' }}>
   
                      <InputBase
                      sx={{ ml: 5, flex: 1 }}
                      placeholder="Search Campaign"
                      inputProps={{ 'aria-label': 'search google maps' }}
                  />


                  <IconButton type="button" sx={{ p: '10px', mr:1}} aria-label="search">
                      <SearchIcon />
                  </IconButton>

                  <Fab variant="extended" sx={{ fontFamily: 'Quicksand, serif', background: '#1c98ad',   marginLeft: { xs: '45%', sm: '50%' }, color: 'white', minWidth: '140px', }}>
                              <AssessmentOutlinedIcon sx={{ mr: 1 }} />
                              Main statistics
                  </Fab>

            </Box>

                    <Box sx={{ width: '100%', mt:5 }}>
                    <Grid container spacing={2}>

      <Grid item xs={6} sm={6} md={3}>
        <Card sx={{    backgroundColor: '#ebfffe',}}>
          <CardContent>
            {/* Your Box component goes here */}
            <Box sx={{ width: '100%', height: 200 }}>
              {/* Your content inside the Box */}
              <Box sx={{  display: 'flex', ml:1}}>
                <LoginIcon sx={{ marginRight: 1 }} />
                <Typography sx={customTextStyleTitle}>Total entries</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // Add additional styling for the box if needed
                  padding: '16px',
                }}
              >
               <Typography sx={customTextStyleNumber}>1500</Typography>
               </Box>    
                        </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
      <Card sx={{    backgroundColor: '#ebfffe',}}>
          <CardContent>
          <Box sx={{ width: '100%', height: 200 }}>
              {/* Your content inside the Box for the second card */}
              <Box sx={{  display: 'flex', ml:1}}>
                <TimerOutlinedIcon sx={{ marginRight: 1 }} />
                <Typography sx={customTextStyleTitle}>Ave. time per user</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // Add additional styling for the box if needed
                  padding: '16px',
                }}
              >
              <Typography sx={customTextStyleNumber}>1:20s</Typography>
            </Box>            </Box>
          </CardContent>
        </Card>
      </Grid>


      <Grid item xs={6} sm={6} md={3}>
        <Card>
          <CardContent>
          <Box sx={{ width: '100%', height: 200 }}>
          <Typography sx={customTextStyleTitle}>Bounce rate</Typography>

              {/* Your content inside the Box for the second card */}
              <Chart options={options} series={series} type="bar" height={200} />

            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={6} sm={6} md={3}>
        <Card>
          <CardContent>
          <Box sx={{ width: '100%', height: 200 }}>
              {/* Your content inside the Box for the second card */}
            </Box>
          </CardContent>
        </Card>
      </Grid>

      
      <Grid item xs={12} sm={12} md={9}>
      <Card sx={{    backgroundColor: '#fffcff',}}>
          <CardContent>
          <Box sx={{ width: '100%', height: 250 }}>
          <Box sx={{  display: 'flex', ml:1}}>
                <DateRangeOutlinedIcon sx={{ marginRight: 1 }} />
                <Typography sx={customTextStyleTitle}>Ave. time per user</Typography>
              </Box>
              {/* Your content inside the Box for the second card */}
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="area"
                width="100%"
                height={250}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card>
          <CardContent>
          <Box sx={{ width: '100%', height: 250 }}>
              {/* Your content inside the Box for the second card */}
            </Box>
          </CardContent>
        </Card>
      </Grid>


    </Grid>

                    </Box>


            </Box>

        </div>
    );
}

export default Statistics;
