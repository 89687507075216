import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress, {  } from '@mui/material/LinearProgress';
import  useState  from 'react';
import { Link } from 'react-router-dom'; // Import Link if using React Router
import { useNavigate, useSearchParams } from 'react-router-dom';
import {Paper } from '@mui/material';
import { SketchPicker } from 'react-color';
import { ColorPicker, useColor } from "react-color-palette";

import lottie from "lottie-web";

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import loading8 from "./loading8.json";

import {useEffect } from 'react';

import { useBrand  } from '../contexts/BrandContext'; // Correct import path


import UpBar from './UpBar'
import SecondUpBar from './SecondUpBar'
import LeftNavBar from './LeftNavBar'

import './styles.css'; // this file and import it
import CompanyCard from './CompanyCard';
import { AppBar, Card } from '@mui/material';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onChildAdded, off, onChildChanged, onValue , set} from 'firebase/database';

import Divider from '@mui/material/Divider';


const pinkColor = "#cd2bff";
const pinkColor2 = 'pink';

const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'
  };

const textStyles = {
  fontFamily: 'Quicksand, serif',
};

  
const NewCampaignEcommerceFinal = () => {


  const [colors, setColors] = React.useState({
    color1: "#11004E",
    color2: "#11004E",
    color3: "#11004E"
  });


    // State for managing the gallery images
    const [primaryImages, setPrimaryImages] = React.useState([]);

  const [activeGalleryIndex, setActiveGalleryIndex] = React.useState(null);
  const secondaryImages = [
    "https://GamifiedMarketing-PullZone.b-cdn.net/tile2.png",
    "https://GamifiedMarketing-PullZone.b-cdn.net/tile2.png",
    "https://GamifiedMarketing-PullZone.b-cdn.net/tile2.png",
    "https://GamifiedMarketing-PullZone.b-cdn.net/711.png",
    "https://GamifiedMarketing-PullZone.b-cdn.net/711.png",
  ];

  const handleToggleGallery = (index) => {
    console.log(`Primary Image URL at index ${index}:`, primaryImages[index]);
    setActiveGalleryIndex(activeGalleryIndex === index ? null : index);
};

  const handleChangeImage = (primaryIndex, secondarySrc) => {

    console.log("primaryImages[primaryIndex]", primaryImages[primaryIndex]);
    console.log("secondarySrc", secondarySrc);


    const formData = new FormData();
    formData.append('primaryImages', primaryImages[primaryIndex]);
    formData.append('secondarySrc', secondarySrc);
    formData.append('campaignID', campaignID);


    // Send form data to the specified endpoint
    fetch('https://gamifiedmarketing.ai:3000', {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json', // Specify content type
        'react-app': 'changeImage' // Add custom header
    },
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Handle response
      console.log('Data primaryImages sent successfully!');
      // You can also navigate to the next page here if needed
    })
    .catch(error => {
      console.error('There was a problem with your fetch operation:', error);
    });


    const updatedImages = [...primaryImages];
    updatedImages[primaryIndex] = secondarySrc;
    setPrimaryImages(updatedImages);
    setActiveGalleryIndex(null);
    
  };

  const handleChangeComplete = (color, colorKey) => {
    setColors({
      ...colors,
      [colorKey]: color.hex
    });
  };


  const buttonCommonStyle = {
    fontFamily: 'Quicksand, serif',
    alignItems: 'center', 
    justifyContent: 'center'
  };


const { currentBrand  } = useBrand (); // Access the currentAccount from AccountContext
const [searchParams] = useSearchParams();
const campaignID = searchParams.get('campaignID');  // Retrieve 'campaignID' from URL query parameters
const [UID, setUID] = React.useState(``);
const [user, setUser] = React.useState(null); // State to hold the user object
const [isGameGenerated, setIsGameGenerated] = React.useState(false);
const navigate = useNavigate();


useEffect(() => {
  if (!UID || !currentBrand  || !campaignID) return;


  console.log("apappapapapapa", campaignID)
  const db = getDatabase();
  const imagesRef = ref(db, `brands/${currentBrand .id}/campaigns/Ecommerce/${campaignID}/images`);

  const fetchImages = () => {
    onValue(imagesRef, (snapshot) => {
      const images = snapshot.val() || {};
      if (typeof images === 'object') {
        setPrimaryImages(Object.values(images));
      } else {
        console.error("Expected images to be an object, but got:", images);
      }
    }, { onlyOnce: true });
  };

  fetchImages();

  return () => {
    // Clean up the subscription
  };
}, [UID, currentBrand , campaignID]);


useEffect(() => {
  let animationInstance;
  if (!isGameGenerated) {
      animationInstance = lottie.loadAnimation({
          container: document.getElementById("animationLoading-container"),
          path: 'https://GamifiedMarketing-PullZone.b-cdn.net/Lotties/alien-ships/alien_ship_9.json',
          renderer: 'svg',
          loop: true,
          autoplay: true
      });
  }

  return () => {
      if (animationInstance) {
          animationInstance.destroy();
      }
  };
}, [isGameGenerated]);



useEffect(() => {
  if (isGameGenerated) {
    // Refresh images logic goes here
    console.log("Game is generated, refreshing primary images.");
    // Assuming the fetchImages function defined earlier, invoke it again:
    if (!UID || !currentBrand  || !campaignID) return;

    const db = getDatabase();
    const imagesRef = ref(db, `users/${UID}/brands/${currentBrand .id}/campaigns/Ecommerce/${campaignID}/images`);

    onValue(imagesRef, (snapshot) => {
      const images = snapshot.val() || {};
      if (typeof images === 'object') {
        setPrimaryImages(Object.values(images));
      } else {
        console.error("Expected images to be an object, but got:", images);
      }
    }, { onlyOnce: true });
  }
}, [isGameGenerated]);



useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setUID(user.uid);
        // Start listening for child_added events when user is authenticated
        if (currentBrand  && currentBrand .id) {
          startListening(user.uid, campaignID);
        }
      
      } else {
        navigate('/login');
      }
    });

    return () => {
      unsubscribeAuth();
      // Stop listening for child_added events when component unmounts
      stopListening(UID, campaignID);
    };
}, [navigate, campaignID, currentBrand ]);



const startListening = (uid, campaignID) => {
    const db = getDatabase();
    const gameGenerationRef = ref(db, `users/${uid}/brands/${currentBrand ?.id}/NewCampaignManual/Ecommerce/${campaignID}/FinishedToGenerateTheGame`);
    onValue(gameGenerationRef, (snapshot) => {
      const status = snapshot.val();
      if (status === true) {
        setIsGameGenerated(true);
      } else if (status === false) {
        setIsGameGenerated(false);
      } else {
        setIsGameGenerated("problem");
      }
    });
  };


  const launchGame = () => {


    const db = getDatabase();

    const statusRef= ref(db, `users/${UID}/brands/${currentBrand .id}/liveCampaigns/Ecommerce/${campaignID}/status`);
    const campaignStatus = "active"
    set(statusRef, campaignStatus)
        .then(() => console.log ("new ecommerce")) // Navigate after successful upload
        .catch(error => console.error("Failed to upload answers to Firebase:", error));


    const url2Ref= ref(db, `users/${UID}/brands/${currentBrand .id}/liveCampaigns/Ecommerce/${campaignID}/url`);
    const campaign_url2 = `https://gamifiedmarketing.ai/JF98DWD/?id=${currentBrand ?.id}&v=${campaignID}`;
    set(url2Ref, campaign_url2)
        .then(() => console.log ("new urlRef")) // Navigate after successful upload
        .catch(error => console.error("Failed to upload answers to Firebase:", error));


    const status2Ref= ref(db, `users/${UID}/brands/${currentBrand .id}/NewCampaignManual/Ecommerce/${campaignID}/status`);
    const campaign2Status = "active"
    set(status2Ref, campaign2Status)
        .then(() => console.log ("new status2Ref")) // Navigate after successful upload
        .catch(error => console.error("Failed to upload answers to Firebase:", error));

        
    const urlRef= ref(db, `users/${UID}/brands/${currentBrand .id}/NewCampaignManual/Ecommerce/${campaignID}/url`);
    const campaign_url = `https://gamifiedmarketing.ai/JF98DWD/?id=${currentBrand ?.id}&v=${campaignID}`;
    set(urlRef, campaign_url)
        .then(() => console.log ("new urlRef")) // Navigate after successful upload
        .catch(error => console.error("Failed to upload answers to Firebase:", error));


    navigate(`/CompanyHome`);
    console.log("game luanched");
  };



const stopListening = (uid, campaignID) => {
  const db = getDatabase();
  const campaignRef = ref(db, `users/${uid}/brands/${campaignID}/NewCampaignManual/Ecommerce/${campaignID}`);
  off(campaignRef);
};

const [iframeSize, setIframeSize] = React.useState({ width: '100%', height: '100%' });

  // Function to refresh iframe
  const refreshIframe = () => {
    setIframeSrc(`${initialSrc}&_=${new Date().getTime()}`); // Append timestamp to URL
  };


// Function to change the iframe size
const changeIframeSize = (width, height) => {
  setIframeSize({ width, height });
};
const initialSrc = `https://gamifiedmarketing.ai/JF98DWD/?id=${currentBrand ?.id}&v=${campaignID}`;
const [iframeSrc, setIframeSrc] = React.useState(initialSrc);


    return (

        <div style={{ display: 'flex', margin: 0, }}>
    <Box sx={{
      width: "100%",
      overflow: 'hidden',
      backgroundColor: '#f5f9ff',
      backgroundImage: 'url("https://GamifiedMarketing-PullZone.b-cdn.net/9307298.png")', // Set the background image here
      // backgroundSize: 'cover', // Ensure the background image covers the entire Box
      backgroundPosition: 'center' // Center the background image
    }}>
            <Box sx={{ width: '100%', position: 'fixed' }}>
                        <UpBar>ggg</UpBar>
                    </Box>

                    <Box sx={{ width: '100%', position: 'fixed',  top: 90, }}>
                        <SecondUpBar></SecondUpBar>
                    </Box>

                <Typography 
                  variant="h4" 
                  sx={{ 
                      color: '#d90d7d', 
                      fontFamily: 'Luckiest Guy, serif', 
                      mb: 2, 
                      mt: 10,  
                      ml: 6, 
                      mr: 6, 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center', // Center vertically
                      textAlign: 'center', // Center horizontally
                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' 
                  }}
              >
   
          {isGameGenerated === true ? "Your pop-up is ready, upload it now!" : isGameGenerated === "problem" ? "We had a problem." : "Your pop-up is in the AI oven and will be served right away!"}
              </Typography>


              <Typography 
                  variant="h5" 
                  sx={{ 
                      color: '#fcba12', 
                      fontFamily: 'Luckiest Guy, serif', 
                      mb: 15, 
                      mt: 3,  
                      ml: 6, 
                      mr: 6, 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center', // Center vertically
                      textAlign: 'center', // Center horizontally
                  }}
              >
                  {isGameGenerated === true ? "you can change some settings if you want to" : isGameGenerated === "problem" ? "Please try later" : "30 seconds on Ave."}

              </Typography>


              {isGameGenerated ? (

  
<Box sx={{
  display: 'flex',
  height: '100vh',
  width: { xs: '80vw', lg: '60vw' }, // 80vw on small to medium screens, 60vw on large screens and above
  justifyContent: 'center', // Ensures horizontal centering
  // alignItems: 'center',     // Ensures vertical centering
  margin: '0 auto',          // Centers the box in the viewport horizontally
  boxSizing: 'border-box' ,

  // Ensures padding and borders are included in width/height calculations
}}>
      <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 }, overflowY: 'auto', height: '100vh' }}>
          <Paper elevation={3} sx={{ p: 2, bgcolor: '#fffefa' }}>
          <Typography 
                  variant="h5" 
                  sx={{ 
                      color: '#fcba12', 
                      fontFamily: 'Luckiest Guy, serif', 
                      mb: 1, 
                      mt: 1,  
                      ml: 6, 
                      mr: 6, 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      justifyContent: 'center', // Center vertically
                      textAlign: 'center', // Center horizontally
                  }}
              >
                 settings

              </Typography>
          <Divider>Texts </Divider>

        <Grid container spacing={2} alignItems="center">
          {/* Existing inputs and labels */}

          <Grid item xs={4}>

            <TextField fullWidth label="Input Text 1" variant="outlined" />
            <Button sx={buttonCommonStyle}  variant="contained" color="primary">
                Save
              </Button>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ color: colors.color1 }}>Output Text 1</Typography>
          </Grid>
          <Grid item xs={4}>

          <Box sx={{ display: 'flex', overflow: 'hidden' , width: '100%', height: '100%'}}>

            <SketchPicker
              color={colors.color1}
              onChangeComplete={(color) => handleChangeComplete(color, 'color1')}
            />

            
          </Box>

          </Grid>

          <Grid item xs={4}>
            <TextField fullWidth label="Input Text 2" variant="outlined" />
            <Button  sx={buttonCommonStyle}  variant="contained" color="primary">
                Save
              </Button>
          </Grid>
          
          <Grid item xs={4}>
            <Typography style={{ color: colors.color2 }}>Output Text 2</Typography>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{ display: 'flex', overflow: 'hidden' , width: '100%', height: '100%'}}>

            <SketchPicker
              color={colors.color2}
              onChangeComplete={(color) => handleChangeComplete(color, 'color2')}
            />
           </Box>
           

          </Grid>

          


        </Grid>
        <Divider>3D Texts </Divider>

        {/* New section for text inputs with image placeholders */}
        <Box mt={4}>
      <Grid container spacing={2} alignItems="center">
        {Array.from({ length: 3 }, (_, index) => (
          <React.Fragment key={index}>
            <Grid item xs={5}>
              <TextField fullWidth label={`New Input ${index + 1}`} variant="outlined" />
            </Grid>
            <Grid item xs={1}>
              <Button  sx={buttonCommonStyle} variant="contained" color="primary">
                Save
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  width: '100%',
                  height: 140,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <img src="https://GamifiedMarketing-PullZone.b-cdn.net/711.png" alt={`Placeholder ${index + 1}`} style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </Box>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
    <Divider>Image Assets </Divider>

        <Box sx={{ }}>
      <Grid container spacing={2}>
        {primaryImages.map((src, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box sx={{ 
                position: 'relative', 
                // height: '100%', 
                // width: '100%',

            maxWidth: '400px', // Sets a maximum width for all images
            maxHeight: '300px', // Sets a maximum height for all images
                display: 'flex', 
                overflow: 'hidden', 
                objectFit: 'contain',
                justifyContent: 'center', 
                alignItems: 'center',
                border: '2px solid gray',
                borderRadius: '25px'
            }}>
              <img src={src} alt={`Primary Image ${index + 1}`} style={{  objectFit: 'contain',  width: '100%',  height: '100%' }} />
              <Button variant="contained" onClick={() => handleToggleGallery(index)} sx={{
                  position: 'absolute',
                  fontFamily: 'Quicksand, serif',
                  bottom: 8, // Position at 8px from the bottom of the image
                  left: '50%',
                  transform: 'translateX(-50%)', // Center the button horizontally
                  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darker on hover
                  }
              }}>
                Change
              </Button>
            </Box>
            {activeGalleryIndex === index && (
              <Grid container spacing={2} style={{ marginTop: '10px' }}>
                {secondaryImages.map((changeSrc, changeIndex) => (
                  <Grid item xs={4} key={changeIndex}>
                    <img src={changeSrc} alt={`Change Image ${changeIndex + 1}`} style={{ width: '100%', height: 'auto' }} />
                    <Button sx={buttonCommonStyle}   variant="contained" size="small" onClick={() => handleChangeImage(index, changeSrc)}>
                      Select
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>

      </Paper>

      </Grid>
      <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 }, height: '100vh', position: 'sticky'}}>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

            {isGameGenerated ? (
               <iframe
               title="Game View"
            src={iframeSrc}
               style={{ width: iframeSize.width, height: iframeSize.height,  borderRadius: '10px' }}
               frameBorder="0"
             />
            ) : (
              <Box style={{ width: iframeSize.width, height: iframeSize.height,  borderRadius: '10px' }}>
                {/* Placeholder for loading animation or message */}

              </Box>
            )}


        <Box sx={{ mt: 2, mb: 3, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button sx={{ ...buttonCommonStyle, mr: 1, ml:1, bgcolor: 'gray' }} variant="contained" onClick={() => changeIframeSize('90%', '90%')}>90% x 90%</Button>
            <Button sx={{ ...buttonCommonStyle, mr: 1, bgcolor: 'gray' }} variant="contained" onClick={() => changeIframeSize('70%', '90%')}>70% x 90%</Button>
            <Button sx={{ ...buttonCommonStyle, mr: 1, bgcolor: 'gray' }} variant="contained" onClick={() => changeIframeSize('60%', '80%')}>60% x 80%</Button>
            <Button sx={{ ...buttonCommonStyle, mr: 1, bgcolor: 'gray' }} variant="contained" onClick={() => changeIframeSize('80%', '40%')}>80% x 40%</Button>
          </Box>

            <Button sx={{ ...buttonCommonStyle, bgcolor: 'primary.main' }} variant="contained" onClick={refreshIframe}>Refresh </Button>


            {isGameGenerated && (
              
              <Button variant="contained" sx={{ mt: 2, mb: 5}} onClick={launchGame}>
                Launch Game
              </Button>
            )}

          </Box>
        </Grid>
      </Grid>
    </Box>
      ) : (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          width: '50%', 
          height: '50%',
          position: 'absolute',  // Position it absolutely within a relative container
          top: '50%',            // Center vertically
          left: '50%',           // Center horizontally
          transform: 'translate(-50%, -50%)'  // Offset the transformation
        }}>
          <div id="animationLoading-container" sx={{ width: "100%", height: "100%" }} />
        </Box>
      )}
      
    </Box>

     </div>

    );
}

export default NewCampaignEcommerceFinal;
