import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Card from '@mui/material/Card';

const pinkColor = "#cd2bff";

const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px',
    width: '100%',
    height: '60px',
    marginBottom: '30px'
};

const textStyles = {
    fontFamily: 'Quicksand, serif',
};

const OnboardingUpload = ({ Text }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const handleFileUpload = (event) => {
        const files = event.target.files;
        const newUploadedFiles = [];
        for (let i = 0; i < files.length; i++) {
            newUploadedFiles.push({ file: files[i], type: Text });
        }
        setUploadedFiles([...uploadedFiles, ...newUploadedFiles]);
    };

    const handleDelete = (index) => {
        const updatedFiles = [...uploadedFiles];
        updatedFiles.splice(index, 1);
        setUploadedFiles(updatedFiles);
    };

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(-1);
    };

    return (
        <Card sx={{ display: 'flex', justifyContent: 'center', marginTop: 1, width: '95%' }}>
            <Box border={0} borderRadius={8} p={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h4" sx={{ color: '#219c9a', fontFamily: 'Quicksand, serif', mb: 1 }}>
                    {Text}
                </Typography>
                {Text === 'Art' && (
                    <Typography variant="h6" sx={{ color: '#219c9a', fontFamily: 'Quicksand, serif', mt: 0 }}>
                        maskot, backgrounds, labels etc. Basically everything that will help our AI understand your design better.
                    </Typography>
                )}

                <Typography variant="h7" sx={{ color: pinkColor, fontFamily: 'Quicksand, serif', mb: 4 }}>
                    You can upload many {Text} versions you might have
                </Typography>

                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id={`file-upload-${Text.toLowerCase()}`}
                    multiple
                    type="file"
                    onChange={handleFileUpload}
                />
                <label htmlFor={`file-upload-${Text.toLowerCase()}`}>
                    <Button component="span" variant="contained" startIcon={<CloudUploadIcon />} style={buttonStyle}>
                        Upload {Text}
                    </Button>
                </label>

                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', gap: '10px' }}>
                    {uploadedFiles.map((item, index) => (
                        item.type === Text && (
                            <Card
                                key={index}
                                sx={{
                                    width: 150,
                                    height: 150,
                                    padding: 2,
                                    position: 'relative'
                                }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {hoveredIndex === index && (
                                    <Button
                                        onClick={() => handleDelete(index)}
                                        style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            zIndex: 1
                                        }}
                                    >
                                        Delete
                                    </Button>
                                )}
                                <img
                                    src={URL.createObjectURL(item.file)}
                                    alt={item.file.name}
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </Card>
                        )
                    ))}
                </div>
            </Box>
        </Card>
    );
}

export default OnboardingUpload;
