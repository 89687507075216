import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress, {  } from '@mui/material/LinearProgress';

import UpBar from './UpBar'
import UpBarAgency from './UpBarAgency'

import SecondUpBar from './SecondUpBar'
import LeftNavBar from './LeftNavBar'
import CampaignStatisticsCard from './CampaignStatisticsCard'
import DiscoverCard from './DiscoverCard'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';


import Fab from '@mui/material/Fab';
import CakeIcon from '@mui/icons-material/Cake';
import './styles.css'; // Create this file and import it
import CompanyCard from './CompanyCard';
import { AppBar, Card } from '@mui/material';

const pinkColor = "#cd2bff";

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
    {
      label: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
    { label: 'The Good, the Bad and the Ugly', year: 1966 },
    { label: 'Fight Club', year: 1999 },
    {
      label: 'The Lord of the Rings: The Fellowship of the Ring',
      year: 2001,
    },
    {
      label: 'Star Wars: Episode V - The Empire Strikes Back',
      year: 1980,
    },
    { label: 'Forrest Gump', year: 1994 },
    { label: 'Inception', year: 2010 },
    {
      label: 'The Lord of the Rings: The Two Towers',
      year: 2002,
    },
    { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { label: 'Goodfellas', year: 1990 },
    { label: 'The Matrix', year: 1999 },
    { label: 'Seven Samurai', year: 1954 },
    {
      label: 'Star Wars: Episode IV - A New Hope',
      year: 1977,
    },
    { label: 'City of God', year: 2002 },
    { label: 'Se7en', year: 1995 },
    { label: 'The Silence of the Lambs', year: 1991 },
    { label: "It's a Wonderful Life", year: 1946 },
    { label: 'Life Is Beautiful', year: 1997 },
    { label: 'The Usual Suspects', year: 1995 },
    { label: 'Léon: The Professional', year: 1994 },
    { label: 'Spirited Away', year: 2001 },
    { label: 'Saving Private Ryan', year: 1998 },
    { label: 'Once Upon a Time in the West', year: 1968 },
    { label: 'American History X', year: 1998 },
    { label: 'Interstellar', year: 2014 },
    { label: 'Casablanca', year: 1942 },
    { label: 'City Lights', year: 1931 },
    { label: 'Psycho', year: 1960 },
    { label: 'The Green Mile', year: 1999 },
    { label: 'The Intouchables', year: 2011 },
    { label: 'Modern Times', year: 1936 },
    { label: 'Raiders of the Lost Ark', year: 1981 },
    { label: 'Rear Window', year: 1954 },
    { label: 'The Pianist', year: 2002 },
    { label: 'The Departed', year: 2006 },
    { label: 'Terminator 2: Judgment Day', year: 1991 },
    { label: 'Back to the Future', year: 1985 },
    { label: 'Whiplash', year: 2014 },
    { label: 'Gladiator', year: 2000 },
    { label: 'Memento', year: 2000 },
    { label: 'The Prestige', year: 2006 },
    { label: 'The Lion King', year: 1994 },
    { label: 'Apocalypse Now', year: 1979 },
    { label: 'Alien', year: 1979 },
    { label: 'Sunset Boulevard', year: 1950 },
    {
      label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
      year: 1964,
    },
    { label: 'The Great Dictator', year: 1940 },
    { label: 'Cinema Paradiso', year: 1988 },
    { label: 'The Lives of Others', year: 2006 },
    { label: 'Grave of the Fireflies', year: 1988 },
    { label: 'Paths of Glory', year: 1957 },
    { label: 'Django Unchained', year: 2012 },
    { label: 'The Shining', year: 1980 },
    { label: 'WALL·E', year: 2008 },
    { label: 'American Beauty', year: 1999 },
    { label: 'The Dark Knight Rises', year: 2012 },
    { label: 'Princess Mononoke', year: 1997 },
    { label: 'Aliens', year: 1986 },
    { label: 'Oldboy', year: 2003 },
    { label: 'Once Upon a Time in America', year: 1984 },
    { label: 'Witness for the Prosecution', year: 1957 },
    { label: 'Das Boot', year: 1981 },
    { label: 'Citizen Kane', year: 1941 },
    { label: 'North by Northwest', year: 1959 },
    { label: 'Vertigo', year: 1958 },
    {
      label: 'Star Wars: Episode VI - Return of the Jedi',
      year: 1983,
    },
    { label: 'Reservoir Dogs', year: 1992 },
    { label: 'Braveheart', year: 1995 },
    { label: 'M', year: 1931 },
    { label: 'Requiem for a Dream', year: 2000 },
    { label: 'Amélie', year: 2001 },
    { label: 'A Clockwork Orange', year: 1971 },
    { label: 'Like Stars on Earth', year: 2007 },
    { label: 'Taxi Driver', year: 1976 },
    { label: 'Lawrence of Arabia', year: 1962 },
    { label: 'Double Indemnity', year: 1944 },
    {
      label: 'Eternal Sunshine of the Spotless Mind',
      year: 2004,
    },
    { label: 'Amadeus', year: 1984 },
    { label: 'To Kill a Mockingbird', year: 1962 },
    { label: 'Toy Story 3', year: 2010 },
    { label: 'Logan', year: 2017 },
    { label: 'Full Metal Jacket', year: 1987 },
    { label: 'Dangal', year: 2016 },
    { label: 'The Sting', year: 1973 },
    { label: '2001: A Space Odyssey', year: 1968 },
    { label: "Singin' in the Rain", year: 1952 },
    { label: 'Toy Story', year: 1995 },
    { label: 'Bicycle Thieves', year: 1948 },
    { label: 'The Kid', year: 1921 },
    { label: 'Inglourious Basterds', year: 2009 },
    { label: 'Snatch', year: 2000 },
    { label: '3 Idiots', year: 2009 },
    { label: 'Monty Python and the Holy Grail', year: 1975 },
  ];
const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'

  };

  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };





const AgencyHome = () => {

    return (

        <div style={{ display: 'flex', height: '100vh', margin: 0, padding: 10}}>

            
            <Box sx={{width:"100%"}}>


                    <Box sx={{ width: '100%' }}>
                        <UpBarAgency></UpBarAgency>
                    </Box>

                    <Box>
                        <SecondUpBar></SecondUpBar>
                    </Box>

                    <Box>

                        <Typography variant="h3" sx={{ mt: 5, ml:5, color: pinkColor, fontFamily: 'Quicksand, serif'}}>
                            Your Worksplace for Papa Johns
                        </Typography>

                        <Fab variant="extended" sx={{ fontFamily: 'Quicksand, serif', background: pinkColor, marginLeft: '70%', color: 'white' }}>
                            <CakeIcon sx={{ mr: 1 }} />
                            New campaign
                        </Fab>


                    </Box>


                    <Typography variant="h5" sx={{ mt: 5, ml:5, color: "#4abfd4", fontFamily: 'Quicksand, serif'}}>
                        Discover, specially for you.
                    </Typography>

                    <Box sx={{
                        ml: 5,
                        mt: 2,
                        width: "200%",
                        height: "480px",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#e8f6fa",
                        borderRadius: "20px",  // Add this line for border radius
                        }}>

                        <DiscoverCard></DiscoverCard>
                        <DiscoverCard></DiscoverCard>
                        <DiscoverCard></DiscoverCard>

                    </Box>

                    


                    <Typography variant="h5" sx={{ mt: 5, ml:5, mb:2, color: "#4abfd4", fontFamily: 'Quicksand, serif'}}>
                        Live Campaigns
                    </Typography>



                        
                <InputBase
                sx={{ ml: 5, flex: 1 }}
                placeholder="Search by Company"
                inputProps={{ 'aria-label': 'search google maps' }}
            />

            <IconButton type="button" sx={{ p: '10px', mr:10}} aria-label="search">
                <SearchIcon />
            </IconButton>

                    
                    <Box sx={{ ml: 5, mt: 1, width: "90%", height: "750px", overflowX: "auto", display: "flex", flexDirection: "row" }}>

                    <CampaignStatisticsCard></CampaignStatisticsCard>
                    <CampaignStatisticsCard></CampaignStatisticsCard>
                    <CampaignStatisticsCard></CampaignStatisticsCard>
                    <CampaignStatisticsCard></CampaignStatisticsCard>
                    <CampaignStatisticsCard></CampaignStatisticsCard>
                    </Box>


            </Box>

        </div>
    );
}

export default AgencyHome;
