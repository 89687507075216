import React, { useState, useEffect } from 'react';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import './styles.css';

const RightSideLogIn = () => {
    const [showSignUp, setShowSignUp] = useState(false);

    useEffect(() => {
        const hashParams = new URLSearchParams(window.location.hash.slice(1));
        if (hashParams.get('signup') === 'true') {
            setShowSignUp(true);
        } else {
            setShowSignUp(false);
        }
    }, []);

    const handleSignUpClick = () => {
        setShowSignUp(!showSignUp);
    };

    const handleSignUpClickSignUp = () => {
        setShowSignUp(!showSignUp);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', overflow: 'hidden' }}>
            {!showSignUp ? (
                <LoginCard onSignUpClick={handleSignUpClick} />
            ) : (
                <SignUp onSignUpClick={handleSignUpClickSignUp} />
            )}
        </div>
    );
}

export default RightSideLogIn;
