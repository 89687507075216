// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* YourCustomStyles.css */
body {
    font-family: 'Quicksand', serif;
    margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,+BAA+B;IAC/B,SAAS;AACb","sourcesContent":["/* YourCustomStyles.css */\nbody {\n    font-family: 'Quicksand', serif;\n    margin: 0;\n}\n\n@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
