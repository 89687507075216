import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import CardMedia from '@mui/material/CardMedia';
import Badge from '@mui/material/Badge';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import './styles.css'; // Create this file and import it

const pinkColor = "#cd2bff";


const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'

  };

  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };



const CompanyCard = () => {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            
            <Badge badgeContent={1} color="primary" overlap="rectangle" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
            {/* Your content goes here */}
            </Badge>


            <Card sx={{ width: 400, height: 450, boxShadow: "0px 4px 8px rgba(205, 43, 255, 0.1)" }}>
                    <CardMedia
                sx={{ height: "50%" }}
                image="images.png"
                title="green iguana"
            />
            
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                    <Box border={1} marginTop={2} height={'100%'} borderRadius={1}>
                        s
                    </Box>
                    </Grid>

                    <Grid item xs={6}>
                    <Box border={1} marginTop={2} height={'100%'}  borderRadius={1}>
                            3
                        </Box>
                    </Grid>
                    
                    <Grid item xs={12}>
                    <Box border={1} marginTop={2} height={'100%'}  borderRadius={1}>
                            3
                    </Box>
                    </Grid>

                </Grid>

            </Card>

        </Box>
    );
}

export default CompanyCard;
