import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, Button, TextField, CircularProgress } from '@mui/material';
import UpBar from './UpBar';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, off, update, get } from 'firebase/database'; // Import 'update' for updating database
import { useBrand } from '../contexts/BrandContext'; // Correct import path
import { signOut } from "firebase/auth";
import Lottie from 'react-lottie';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'; // Import the OpenInBrowser icon
import {Dialog, Divider} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'; // Import the icon
import IconButton from '@mui/material/IconButton'; // Ensure IconButton is imported
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Switch from '@mui/material/Switch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const NewCampaignEcommerce = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const campaignIdFromURL = queryParams.get('c');

  const { currentBrand, setCurrentBrand } = useBrand();
  console.log("currentBrand", currentBrand);
  const [user, setUser] = React.useState(null); // State to hold the user object
  const [selectedButton, setSelectedButton] = useState(null); // State for toggled buttons
  const [campaignDesc, setCampaignDesc] = useState(''); // State for campaign description
  const [productImage, setProductImage] = useState(null); // State for product image
  const [productDesc, setProductDesc] = useState(''); // State for product description
  const [iframeUrl, setIframeUrl] = useState(null); // State to hold the iframe URL
  const [campaignStatus, setCampaignStatus] = useState(null); // New state to track campaign status
  const [animationData, setAnimationData] = useState(null);
  const [loading, setLoading] = useState(false); // State to track loading status
  const [images, setImages] = useState([]);
  const [threeDtexst, setThreeDtexst] = useState([]);
  const [campaignID, setCampaignID] = useState('');
  const [optionalImages, setOptionalImages] = useState([]);
  const [finalPickLoading, setFinalPickLoading] = useState(false); // New state for final pick loading
  const [open, setOpen] = React.useState(false);
  const [phoneLegalDeclare, setPhoneLegalDeclare] = useState('');
  const [phoneCheckMark, setPhoneCheckMark] = useState(false);
  const [alignment, setAlignment] = useState('');
  const [couponEnabled, setCouponEnabled] = useState(false);
  const [couponValue, setCouponValue] = useState('');
  const [shortURL, setShortURL] = useState('');

  const [couponDetails, setCouponDetails] = useState('');
  const [copied, setCopied] = useState(false);

  const [isFormDataLoaded, setIsFormDataLoaded] = useState(false); // New state to track if form data is loaded

  const [legalStatement, setLegalStatement] = useState('');
  const [checkMark, setCheckMark] = useState(false);

  const [nameClicked, setNameClicked] = useState(false);
  const [emailClicked, setEmailClicked] = useState(false);
  const [phoneClicked, setPhoneClicked] = useState(false);

  const [videoLoading, setVideoLoading] = useState(true);
  const [videoSrc, setVideoSrc] = useState('');


  const [buttonLinkEnabled, setButtonLinkEnabled] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [actualLink, setActualLink] = useState('');
  const [buttonLinkRevealTime, setButtonLinkRevealTime] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);
  const [textCopyWrite, setTextCopyWrite] = useState(''); // State for text content


  const buttonLabels = ['Social media post/ad', 'E-commerce pop-up', 'SMS/ Whatsapp message'];
  const [EndUse, setEndUse] = useState(buttonLabels[0]);


  const handleCouponDetailsChange = (event) => {
    setCouponDetails(event.target.value);
  };

  const handleCouponDetailsSubmit = () => {
    handleFormChange({ CouponDetails: couponDetails });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`<iframe src="${shortURL}" width="100%" height="600px" style="border:none;"></iframe>`);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); // Reset after 3 seconds
  };



  useEffect(() => {
    const fetchVideo = async (retryCount = 0) => {
      try {
        console.log(`Attempting to fetch video. Retry count: ${retryCount}`);
        const response = await fetch(`https://gamifiedmarketing-pullzone.b-cdn.net/brands/${currentBrand?.id}/${campaignID}/video_preview.mp4?timestamp=${new Date().getTime()}`);
    
        if (response.ok) {
          console.log("Video fetched successfully.");
          setVideoSrc(response.url);
          console.log("TRUE--->>> Video source set to:", response.url);
          setVideoLoading(false);
        } else {
          console.log(`Failed to fetch video. Response status: ${response.status}`);
          if (retryCount < 5) {
            console.log(`Retrying fetch in 10 seconds... Attempt #${retryCount + 1}`);
            setTimeout(() => fetchVideo(retryCount + 1), 10000); // Retry after 10 seconds
          } else {
            console.error('Failed to load video after multiple attempts.');
            setVideoLoading(false); // Stop loading after retries
          }
        }
      } catch (error) {
        console.error(`Error occurred while fetching video: ${error.message}`);
        if (retryCount < 5) {
          console.log(`Retrying fetch in 10 seconds... Attempt #${retryCount + 1}`);
          setTimeout(() => fetchVideo(retryCount + 1), 10000); // Retry after 10 seconds
        } else {
          console.error('Failed to load video after multiple attempts.');
          setVideoLoading(false); // Stop loading after retries
        }
      }
    };
  
    if (iframeUrl) {  // Ensure that the iframeUrl is available before attempting to fetch the video
      console.log("iframeUrl is available, starting video fetch...");
      fetchVideo();
    } else {
      console.log("iframeUrl is not available, skipping video fetch.");
    }
  }, [iframeUrl, currentBrand?.id, campaignID]);
  


useEffect(() => {
  const fetchFormData = async () => {

    try {

      console.log("KJKJKJKJKJKJKJKJKJKJKJKJKJKJKJKJK")
      console.log("KJKJKJKJKJKJKJKJKJKJKJKJKJKJKJKJK")
      console.log("KJKJKJKJKJKJKJKJKJKJKJKJKJKJKJKJK")
      console.log("KJKJKJKJKJKJKJKJKJKJKJKJKJKJKJKJK")

      const campaignIdFromURL = queryParams.get('c');

      const response = await fetch(`https://gamifiedmarketing-pullzone.b-cdn.net/brands/${currentBrand?.id}/${campaignID}/FormData.json`);
      const formData = await response.json();

      console.log("formDataformDataformData", formData)
      console.log("formDataformDataformData", formData)
      console.log("formDataformDataformData", formData)
      console.log("formDataformDataformData", formData)
      console.log("formDataformDataformData", formData)
      console.log("formDataformDataformData", formData)
      console.log("formDataformDataformData", formData)

      const db = getDatabase();
      const endUseRef = ref(db, `users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/EndUse`);
      const endUseSnapshot = await get(endUseRef);
      const endUse = endUseSnapshot.val();
      setEndUse(endUse);


      setNameClicked(formData.Name === true);
      setEmailClicked(formData.Email === true);
      setPhoneClicked(formData.Phone === true);


      setCouponEnabled(formData.Coupon === true);
      setCouponValue(formData.CouponText || '');
      setAlignment(formData.CouponTiming || '');


      setButtonLinkEnabled(formData.LinkButton === true);
      setButtonText(formData.ButtonText || '');
      setActualLink(formData.Link || '');
      setButtonLinkRevealTime(formData.LinkButtonTiming || '');

      setIsFormDataLoaded(true); // Mark the form data as loaded


    } catch (error) {
      console.error('Error fetching form data:', error);
    } finally {
      setInitialLoad(false);
    }
  };

  fetchFormData();
  
}, [currentBrand?.id, campaignIdFromURL, campaignStatus, user, campaignID]);


const handleButtonLinkToggle = (event) => {
  const checked = event.target.checked;
  setButtonLinkEnabled(checked);
  handleFormChange({ LinkButton: checked });
};

const handleButtonTextChange = (event) => {
  setButtonText(event.target.value);
};

const handleButtonTextSubmit = () => {
  handleFormChange({ ButtonText: buttonText });
};

const handleActualLinkChange = (event) => {
  setActualLink(event.target.value);
};


const handleActualLinkSubmit = () => {
  handleFormChange({ Link: actualLink });
};


const handleButtonLinkRevealTimeChange = (event, newAlignment) => {
  if (newAlignment !== null) {
    setButtonLinkRevealTime(newAlignment);
    handleFormChange({ LinkButtonTiming: newAlignment });
  }
};

const handleChangeAlignment = (event, newAlignment) => {
  if (newAlignment !== null) {
    setAlignment(newAlignment);
    handleFormChange({ CouponTiming: newAlignment });
  }
};


const handleCouponToggle = (event) => {
  const checked = event.target.checked;
  setCouponEnabled(checked);
  handleFormChange({ Coupon: checked });
};


const handleCouponChange = (event) => {
  setCouponValue(event.target.value);
};

const handleCouponSubmit = () => {
  handleFormChange({ CouponText: couponValue });
};


  const [openDialog, setOpenDialog] = useState(false);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null); // Track the index of the hovered image
  const [hover3DtextIndex, sethover3DtextIndex] = useState(null); // Track the index of the hovered image
  const [textInput, setTextInput] = useState('');
  const [selectedImageDescription, setSelectedImageDescription] = useState('');
  const [generateDisabled, setGenerateDisabled] = useState(true);
  const [newImagePreview, setNewImagePreview] = useState(null); // For new image preview during generation
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingVersion, setIsGeneratingVersion] = useState(false);

  const [loading3DTextIndex, setLoading3DTextIndex] = useState(null);

  const [activeVersionButton, setVersionActiveButton] = useState(null);
  const [UserUploadOwn, setUserUploadOwn] = useState(null);

  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [qrUrl, setQrUrl] = useState('');

  const [reqNumber, setreqNumber] = useState(null);


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [twoDtexts, setTwoDtexts] = useState([]);
  const [twoDfiles, setTwoDfiles] = useState([]);
  const [loading2DTextIndex, setLoading2DTextIndex] = useState(null);
  

  const handle2DTextChange = (index, value) => {
    const newTwoDtexts = [...twoDtexts];
    newTwoDtexts[index] = value;
    setTwoDtexts(newTwoDtexts);
  };

  const handle2DFileChange = (index, value) => {
    const newTwoDfiles = [...twoDfiles];
    newTwoDfiles[index] = value;
    setTwoDfiles(newTwoDfiles);
  };
  

  const handleCheckMarkChange = (event) => {
    const checked = event.target.checked;
    setCheckMark(checked);
    handleFormChange({ checkMark: checked });
  };


  const handleLegalStatementSubmit = () => {
    handleFormChange({ legalStatement });
  };

  const handleFormChange = async (data) => {
    try {
      const formData = new FormData();
      formData.append('userUid', user.uid);
      formData.append('campaignID', campaignID);
      formData.append('currentBrand', currentBrand.id);
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });

      console.log("formData", formData);


      await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: { 'react-app': 'changeFormData' },
        body: formData,
      });
    } catch (error) {
      console.error('Error updating form data:', error);
    } finally {
      refreshIFrame();
    }
  };


  // useEffect(() => {
  //   const fetchTextCopyWrite = async () => {
  //     try {
  //       const response = await fetch(`https://gamifiedmarketing-pullzone.b-cdn.net/brands/${currentBrand?.id}/${campaignID}/FormData.json`);
  //       const formData = await response.json();
  
  //       if (formData?.EndUse === 'SMS/ Whatsapp message') {
  //         setTextCopyWrite(formData.TextCopyWrite || '');
  //         console.log("textCopyWrite===========>", textCopyWrite);

  //       }
  //     } catch (error) {
  //       console.error('Error fetching TextCopyWrite:', error);
  //     }
  //   };
  
  //   if (user && currentBrand && campaignID) {
  //     fetchTextCopyWrite();
  //   }
  // }, [user, currentBrand, campaignID]);
  


  useEffect(() => {
    const fetchFormData = async () => {
      console.log("KOKOKOKOOKKOOKOK")
      console.log("KOKOKOKOOKKOOKOK")
      console.log("KOKOKOKOOKKOOKOK")
      console.log("KOKOKOKOOKKOOKOK")
      console.log("KOKOKOKOOKKOOKOK")
      console.log("KOKOKOKOOKKOOKOK")
      try {

        const timestamp = new Date().getTime();
        const response = await fetch(`https://gamifiedmarketing-pullzone.b-cdn.net/brands/${currentBrand?.id}/${campaignID}/FormData.json?ts=${timestamp}`);

        const formData = await response.json();

      const db = getDatabase();
      const shortURLRef = ref(db, `users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/ShortURL`);
      const shortURLSnapshot = await get(shortURLRef);
      const shortURL = shortURLSnapshot.val();
      setShortURL(shortURL);


      const endUseRef = ref(db, `users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/EndUse`);
      const endUseSnapshot = await get(endUseRef);
      const endUse = endUseSnapshot.val();
      setEndUse(endUse);


      const updatedTextCopyWrite = formData.TextCopyWrite ? `"${formData.TextCopyWrite}${shortURL ? ` ${shortURL}` : ''}"` : '';

      setTextCopyWrite(updatedTextCopyWrite);


        setNameClicked(formData.Name === true);
        setEmailClicked(formData.Email === true);
        setPhoneClicked(formData.Phone === true);
        setCouponEnabled(formData.Coupon === true);
        setCouponValue(formData.CouponText || '');
        setAlignment(formData.CouponTiming || '');
        setButtonLinkEnabled(formData.LinkButton === true);
        setButtonText(formData.ButtonText || '');
        setActualLink(formData.Link || '');
        setButtonLinkRevealTime(formData.LinkButtonTiming || '');
        setIsFormDataLoaded(true); // Mark the form data as loaded

        console.log("KOKOKOKOOKKOOKOK33333333333333")
        console.log("KOKOKOKOOKKOOKOK33333333333333")
        console.log("KOKOKOKOOKKOOKOK33333333333333")
        console.log("KOKOKOKOOKKOOKOK33333333333333")
        console.log("KOKOKOKOOKKOOKOK")
        console.log("KOKOKOKOOKKOOKOK")

      } catch (error) {
        console.error('Error fetching form data:', error);
      } finally {
        setInitialLoad(false);
      }
    };
  
    fetchFormData();
  }, [currentBrand?.id, campaignIdFromURL, campaignStatus, user, campaignID]);



  const handleNameClick = (value) => {
    setNameClicked(value);
    handleFormChange({ Name: value });
  };

  const handleEmailClick = (value) => {
    setEmailClicked(value);
    handleFormChange({ Email: value });
  };

  const handlePhoneClick = (value) => {
    setPhoneClicked(value);
    handleFormChange({ Phone: value });
  };



  const handle2DtextChangeClick = async (index) => {
    setLoading2DTextIndex(index);
    console.log(`Change 2D text for text ${index} to: ${twoDtexts[index]}`);
    try {
      const formData = new FormData();
      formData.append('campaignID', campaignID);
      formData.append('userUid', user.uid);
      formData.append('currentBrand', currentBrand?.id);
      formData.append('TwoDTextIndex', index);
      formData.append('twoDtextContent', twoDtexts[index]);
      formData.append('twoDfileName', twoDfiles[index]);

  
      const response = await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: { 'react-app': 'Change2DText' },
        body: formData,
      });
  
      if (response.ok) {
        console.log("response.ok after Data --> setQrDialogOpen setQrUrl");
      } else {
        throw new Error('Failed to update 2D text');
      }
    } catch (error) {
      console.error('Error updating 2D text:', error);
    } finally {
      setLoading2DTextIndex(null);
      refreshtexts2D();
      refreshIFrame();
    }
  };

  const refreshtexts2D = async () => {
    const db = getDatabase();
    const textsRef = ref(db, `users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/2Dtexts`);
    const snapshot = await get(textsRef);
    const textsData = snapshot.val();
  
    if (textsData) {
      const loadedTexts = [];
      const loadedFiles = [];
      Object.values(textsData).forEach((textData) => {
        loadedTexts.push(textData.text);
        loadedFiles.push(textData.file);
      });
      setTwoDtexts(loadedTexts);
      setTwoDfiles(loadedFiles);
      console.log('Loaded texts:', loadedTexts);
      console.log('Loaded files:', loadedFiles);
    } else {
      console.log('No texts found');
      setTwoDtexts([]);
      setTwoDfiles([]);
    }
  };
  
  useEffect(() => {
    if (user && currentBrand && campaignID) {
      refreshtexts2D();
    }
  }, [user, currentBrand, campaignID, campaignStatus]);
  

  const buttoneImages = [
    'https://GamifiedMarketing-PullZone.b-cdn.net/icecream1.png',
    'https://GamifiedMarketing-PullZone.b-cdn.net/icecream2.png',
    'https://GamifiedMarketing-PullZone.b-cdn.net/icecream3.png',
    'https://GamifiedMarketing-PullZone.b-cdn.net/icecream4.png',
  ];



  const handleStyleButtonClick = (index) => {
    setVersionActiveButton(index); // Set the clicked button as active
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  
  const [isChecked, setIsChecked] = useState(false);
  const [userImagePreview, setUserImagePreview] = useState(null);
  const [userImageFile, setUserImageFile] = useState(null); // State to hold the file object
  const [removingBackground, setRemovingBackground] = useState(false);
  

    const [threeDtexts, setThreeDtexts] = useState([]);
  
    const handle3DTextChange = (index, value) => {
      const newThreeDtexts = [...threeDtexts];
      newThreeDtexts[index] = value;
      setThreeDtexts(newThreeDtexts);
    };
  


const handle3DtextChangeClick = async (index) => {

  setLoading3DTextIndex(index);

      // Logic to update the 3D text content based on threeDtexts[index]
      console.log(`Change 3D text for image ${index} to: ${threeDtexts[index]}`);
      try {

        // Generate the QR code via fetch if it doesn't exist
        const formData = new FormData();
        formData.append('campaignID', campaignID);
        formData.append('userUid', user.uid);
        formData.append('currentBrand', currentBrand?.id);
        formData.append('ThreeDTextIndex', index);
        formData.append('threeDtextContent', threeDtexts[index]);
        
        
        const response = await fetch('https://gamifiedmarketing.ai:3000', {
            method: 'POST',
            headers: { 'react-app': 'Change3Dtext' },
            body: formData,
        });

        if (response.ok) {
          console.log(" response.ok after Data --> setQrDialogOpen setQrUrl")
            // setQrDialogOpen(true);
        } else {
            throw new Error('Failed to generate QR code');
        }
    
    } catch (error) {
        console.error('Error checking or generating QR code:', error);
    }    finally {

      setLoading3DTextIndex(null);
      refreshtexts3D();
      refreshIFrame();

    }


    };


  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const openInNewTab = (url) => window.open(url, '_blank');

  const handleImageSelection = (description) => {
    setTextInput(description); // Set the TextField value to the description of the clicked button

    if (selectedImageDescription === description) {
      setSelectedImageDescription('');  // Unselect if clicked again
    } else {
      setSelectedImageDescription(description);
    }
    setGenerateDisabled(!description && !textInput);  // Update button enable state based on selection
  };
  
  const handleInputChange = (event) => {
    const value = event.target.value;
    setTextInput(value);
    setGenerateDisabled(!value && !selectedImageDescription);  // Enable button if there is text or a selected image

  };


  useEffect(() => {

    console.log("HELLO")
    const fetchData = async () => {
      console.log("campaignIdFromURL", campaignIdFromURL)
      if (campaignIdFromURL && currentBrand?.id && user?.uid) {

        const db = getDatabase();
        const imagesRef = ref(db, `users/${user?.uid}/brands/${currentBrand?.id}/campaigns/${campaignIdFromURL}/gameType`);
        const snapshot = await get(imagesRef);
        const gameTypeValue = snapshot.val();
        console.log("gameTypeValue------->>>>>", gameTypeValue);

        setCampaignID(campaignIdFromURL);
        setCampaignStatus('success');
        const newIframeUrl = `https://gamifiedmarketing.ai/${gameTypeValue}/?id=${currentBrand?.id}&v=${campaignIdFromURL}&timestamp=${new Date().getTime()}`;
        setIframeUrl(newIframeUrl);
        refreshtexts3D();
      }else{
        console.log("PROBLEMINTO")
      }
    };

    fetchData();
  }, [campaignIdFromURL, currentBrand?.id, user?.uid]);



const ShowQR = async () => {
  setQrDialogOpen(true); // Open the dialog immediately
  if (qrUrl) { 
    return;
  }
  
    const NewqrUrl = `https://GamifiedMarketing-PullZone.b-cdn.net/brands/${currentBrand?.id}/${campaignID}/perviewQR.png`;
    try {
            // Generate the QR code via fetch if it doesn't exist
            const formData = new FormData();
            formData.append('campaignID', campaignID);
            formData.append('userUid', user.uid);
            formData.append('currentBrand', currentBrand?.id);
            formData.append('reqNumber', reqNumber);
            formData.append('selectedImageIndex', selectedImageIndex);
            formData.append('imgURL', images[selectedImageIndex]);
            formData.append('userImagePreview', userImagePreview);
            formData.append('iframeUrl', iframeUrl);
            
            const response = await fetch('https://gamifiedmarketing.ai:3000', {
                method: 'POST',
                headers: { 'react-app': 'GenerateQRForPreview' },
                body: formData,
            });

            if (response.ok) {
              console.log(" response.ok after Data --> setQrDialogOpen setQrUrl")
                // setQrDialogOpen(true);
              setQrUrl(NewqrUrl);
            } else {
                throw new Error('Failed to generate QR code');
            }
        
    } catch (error) {
        console.error('Error checking or generating QR code:', error);
    }
};


  const handleUseUserImage = async () => {


    setUserUploadOwn(true)
    setIsGeneratingVersion(true); // Indicate that generating process has started
    
    console.log("images inside handleUseUserImage---------",images )
    console.log(selectedImageIndex, images[selectedImageIndex])
    const formData = new FormData();
    formData.append('campaignID', campaignID);
    formData.append('userUid', user.uid); // Convert user object to JSON string
    formData.append('currentBrand', currentBrand?.id);
    formData.append('reqNumber', reqNumber);
    formData.append('selectedImageIndex', selectedImageIndex);
    formData.append('imgURL', images[selectedImageIndex]);
    formData.append('userImagePreview', userImagePreview);
    formData.append('userImageFile', userImageFile);

    try {
      const response = await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: {
          'react-app': 'UserUploadedImage'
        },
        body: formData
      });

      const responseData = await response.json();
      if (response.ok) {

        console.log('Campaign generated responseData:', responseData);
        console.log('Campaign generated response:', response);

      } else {
        throw new Error(responseData.message || 'Failed to generate campaign');
      }
    } catch (error) {
      console.error('Error generating campaign:', error);

    }
    finally {
      setIsGeneratingVersion(false); // Reset generating process
      setUserUploadOwn(false);
      handleImageDialogClose(); // Close the dialog
      refreshIFrame(); // Function to refresh the iframe
      refreshImages();

    }

  };














    const handleImageVersionSelect = async (VersionNum) => {

    setIsGeneratingVersion(true); // Indicate that generating process has started

    const formData = new FormData();
    formData.append('campaignID', campaignID);
    formData.append('userUid', user.uid); // Convert user object to JSON string
    formData.append('currentBrand', currentBrand.id);
    formData.append('reqNumber', reqNumber);
    formData.append('selectedImageIndex', selectedImageIndex);
    formData.append('imgURL', images[selectedImageIndex]);

    formData.append('VersionNum', VersionNum);

    try {
      const response = await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: {
          'react-app': 'ChosenNewImageAssetVersion' // Custom header
        },
        body: formData
      });

      const responseData = await response.json();
      if (response.ok) {

        console.log('Campaign generated responseData:', responseData);
        console.log('Campaign generated response:', response);

      } else {
        throw new Error(responseData.message || 'Failed to generate campaign');
      }
    } catch (error) {
      console.error('Error generating campaign:', error);

    }
    finally {
      setIsGeneratingVersion(false); // Reset generating process
      handleImageDialogClose(); // Close the dialog
      refreshIFrame(); // Function to refresh the iframe
      refreshImages();
    }

  };
















  // Function to refresh images
// Function to refresh images with cache-busting URL
const refreshImages = async () => {
  setLoading(true); // Optional: show a loading indicator
  const db = getDatabase();
  const imagesRef = ref(db, `users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/images`);
  const snapshot = await get(imagesRef);
  const imagesData = snapshot.val();

  if (imagesData) {
    const loadedImages = Object.values(imagesData).map(imageUrl => {
      // Append a unique query parameter to the image URLindex
      const cacheBustParam = `?timestamp=${Date.now()}`;
      return imageUrl + cacheBustParam;
    });
    setImages(loadedImages); // This will trigger a re-render of the component with fresh image URLs
    console.log(loadedImages);
  } else {
    console.log('No images found');
    setImages([]); // Clear the images if none found, triggers re-render with empty array
  }

  setLoading(false); // Optional: hide the loading indicator
};


const refreshtexts3D = async () => {

  const db = getDatabase();
  const imagesRef = ref(db, `users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/3Dtexts`);
  const snapshot = await get(imagesRef);
  const imagesData = snapshot.val();

  if (imagesData) {
    const loadedImages = Object.values(imagesData).map(imageUrl => {
      // Append a unique query parameter to the image URLindex
      const cacheBustParam = `?timestamp=${Date.now()}`;
      return imageUrl + cacheBustParam;
    });
    setThreeDtexst(loadedImages); // This will trigger a re-render of the component with fresh image URLs
    console.log(loadedImages);
  } else {
    console.log('No images found');
    setThreeDtexst([]); // Clear the images if none found, triggers re-render with empty array
  }

  setLoading(false); // Optional: hide the loading indicator
};




  // Function to refresh the iframe
  const refreshIFrame = () => {
    setIframeUrl(`${iframeUrl}&timestamp=${new Date().getTime()}`);
  };

  const handleImageDialogOpen = (index) => {
    if (isGenerating) {
      setSnackbarMessage("Loading. Can't exit. Needs to wait for th.");
      setSnackbarOpen(true);
      return; // Prevent opening a new dialog if one is generating
    }
    setNewImagePreview(null); // Set the new image URL to state
    setUserImagePreview(null);
    setSelectedImageIndex(index);
    setImageDialogOpen(true);
    refreshImages();

  };


  const handleImageDialogClose = () => {
    if (isGenerating) {
      setSnackbarMessage("Loading. Can't exit. Needs to wait.");
      setSnackbarOpen(true);
      return; // Prevent closing the dialog if generating
    }
    
    setImageDialogOpen(false);
    refreshImages().catch(console.error);

  };


  const handleRemoveBackgroundUserImage = async () => {

    setRemovingBackground(true); // Start loading when the process begins

    const formData = new FormData();
    formData.append('campaignID', campaignID);
    formData.append('userUid', user.uid); // Convert user object to JSON string
    formData.append('currentBrand', currentBrand?.id);
    formData.append('reqNumber', reqNumber);
    formData.append('selectedImageIndex', selectedImageIndex);
    formData.append('imgURL', images[selectedImageIndex]);

    formData.append('userImage', userImageFile); // Append the file object, not the URL


    try {
      const response = await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: {
          'react-app': 'removeBackground' // Custom header
        },
        body: formData
      });

      const responseData = await response.json();
      if (response.ok) {

        console.log('Campaign generated responseData:', responseData);
        console.log('Campaign generated responseData.message:', responseData.message);
        setUserImagePreview(responseData.message);


      } else {
        throw new Error(responseData.message || 'Failed to generate campaign');
      }
    } catch (error) {
      console.error('Error generating campaign:', error);

    }
    finally {
      setUserImageFile(null);
      // setIsGeneratingVersion(false); // Reset generating process
      // handleImageDialogClose(); // Close the dialog
      // refreshIFrame(); // Function to refresh the iframe
      setRemovingBackground(false); // End loading when the process finishes

    }

  };

  useEffect(() => {
    const url = loading ? 'https://GamifiedMarketing-PullZone.b-cdn.net/Lotties/gems/gem_4.json' : 'https://GamifiedMarketing-PullZone.b-cdn.net/Lotties/alien-ships/alien_ship_3.json';
  
    fetch(url)
      .then(response => response.json())
      .then(data => setAnimationData(data))
      .catch(error => console.error('Error fetching animation data:', error));
  }, [loading]);


  useEffect(() => {

    console.log("insid")
    if (user && currentBrand && campaignID) {
      const db = getDatabase();
      const imagesRef = ref(db, `users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/images`);
      onValue(imagesRef, (snapshot) => {
        const imagesData = snapshot.val();
        const loadedImages = [];
        for (let key in imagesData) {
          loadedImages.push(imagesData[key]);
        }
        setImages(loadedImages);
        console.log("loadedImages", loadedImages)
        console.log(`users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/images`)
      }, {
        onlyOnce: true
      });
    }
  }, [user, currentBrand, campaignID, campaignStatus]);



  useEffect(() => {

    console.log("insid")
    if (user && currentBrand && campaignID) {
      const db = getDatabase();
      const imagesRef = ref(db, `users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/3Dtexts`);
      onValue(imagesRef, (snapshot) => {
        const imagesData = snapshot.val();
        const loadedImages = [];
        for (let key in imagesData) {
          loadedImages.push(imagesData[key]);
        }
        setThreeDtexst(loadedImages);
        console.log("loadedImages", loadedImages)
        console.log(`users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/3Dtexts`)
      }, {
        onlyOnce: true
      });
    }
  }, [user, currentBrand, campaignID, campaignStatus]);




  

  useEffect(() => {
    if (user && campaignID) {
      const db = getDatabase();
      const optionalImagesRef = ref(db, `users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/optionalAdditionalImages`);

      const unsubscribe = onValue(optionalImagesRef, (snapshot) => {
        const data = snapshot.val() || {};
        console.log(`users/${user.uid}/brands/${currentBrand?.id}/campaigns/${campaignID}/optionalAdditionalImages`, "0999999999999999999", data)
        setOptionalImages(Object.values(data));
      });

      return () => unsubscribe();
    }
  }, [user, campaignID, currentBrand, campaignStatus]);


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  const navigate = useNavigate();



  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);

      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate, setCurrentBrand]);


  
  const generateCampaignID = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = 'C'; // Start with 'C'
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };



  const handleButtonToggle = (index) => {
    setSelectedButton(selectedButton === index ? null : index);
    setEndUse(buttonLabels[index]);
  };

  const handleCampaignDescChange = (event) => {
    setCampaignDesc(event.target.value);
  };

  const handleProductDescChange = (event) => {
    setProductDesc(event.target.value);
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    setUserImagePreview(URL.createObjectURL(file));
    setUserImageFile(file); // Save the file object
  };


  const handleImageChange = (event) => {
    setProductImage(event.target.files[0]);
  };















  const handleGenerate = async () => {

    setIsGenerating(true); // Start generating process
    setLoading(true);


    console.log('Generated content:', textInput || selectedImageDescription);

    const newImageDescription = textInput || selectedImageDescription;

    const reqNumber = Array.from(Array(5), () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'[Math.floor(Math.random() * 62)]).join('');

    setreqNumber(reqNumber); // Start generating process

    const formData = new FormData();
    formData.append('campaignID', campaignID);
    formData.append('userUid', user.uid); // Convert user object to JSON string
    formData.append('currentBrand', currentBrand?.id);
    formData.append('newImageDescription', newImageDescription);
    formData.append('selectedImageIndex', selectedImageIndex);
    formData.append('reqNumber', reqNumber);
    formData.append('activeVersionButton', activeVersionButton);
    formData.append('imgURL', images[selectedImageIndex]);

    try {
      const response = await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: {
          'react-app': 'GenerateNewImageAssets' // Custom header
        },
        body: formData
      });
      const responseData = await response.json();
      if (response.ok) {


        console.log('handleGenerate responseData:', responseData);
        console.log("responseData.message", responseData.message);
        
        setNewImagePreview(responseData.message); // Update this to handle multiple URLs

        console.log('handleGenerate response.body:', response.body);

        refreshIFrame();
        refreshImages();
      } else {
        throw new Error(responseData.message || 'Failed to generate campaign');
      }
    } catch (error) {

      console.error('Error generating campaign:', error);
    }
    finally {
      console.log("HERE WE ARE!")
      refreshIFrame();
      refreshImages();

      setIsGenerating(false); // End generating process
      setLoading(false);
    }
  };



  const handleSubmit = async () => {
    setLoading(true);
    setOpen(true);

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 500);


    const newCampaignID = generateCampaignID();
    setCampaignID(newCampaignID);

    const formData = new FormData();
    formData.append('EndUse', EndUse);
    formData.append('campaignID', newCampaignID);
    formData.append('selectedButton', selectedButton);
    formData.append('campaignDesc', campaignDesc);
    formData.append('productDesc', productDesc);
    formData.append('userUid', user.uid); // Convert user object to JSON string
    formData.append('userEmail', user.email); // Convert user object to JSON string
    formData.append('currentBrand', currentBrand?.id);
    formData.append('isChecked', isChecked);
    formData.append('activeVersionButton', activeVersionButton + 1);

    if (productImage) {
      formData.append('productImage', productImage, productImage.name);
    }

    try {
      const response = await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: {
          'react-app': 'NewCampaignReq' // Custom header
        },
        body: formData
      });
      const responseData = await response.json();
      if (response.ok) {
        console.log('Campaign generated responseData:', responseData);
        console.log('Campaign generated response:', response);
        console.log("newCampaignIDnewCampaignIDnewCampaignIDnewCampaignID", newCampaignID)
        setIframeUrl(`https://gamifiedmarketing.ai/${responseData.message}/?id=${currentBrand?.id}&v=${newCampaignID}&timestamp=${new Date().getTime()}`);
        console.log(`https://gamifiedmarketing.ai/${responseData.message}/?id=${currentBrand?.id}&v=${newCampaignID}`);
        setCampaignStatus('success');

        const newURL = `${window.location.origin}${window.location.pathname}?b=${currentBrand?.id}&c=${newCampaignID}`;
        window.history.replaceState(null, '', newURL);


      } else {
        throw new Error(responseData.message || 'Failed to generate campaign');
      }
    } catch (error) {
      console.error('Error generating campaign:', error);
      setCampaignStatus('error');

    }
    finally {
      setLoading(false);
    }
  };

  const isGenerateDisabled = selectedButton === null || campaignDesc.trim() === '';

    return (
        <Box sx={{
            width: "100%",
            overflow: 'hidden',
            backgroundColor: '#f5f9ff',
            backgroundImage: 'url("https://GamifiedMarketing-PullZone.b-cdn.net/9307298.png")',
            backgroundPosition: 'center'
        }}>

      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
        Your campaign is underway! We will send you an email once it's ready.
        </Alert>
      </Snackbar>

      <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
      </Snackbar>


            <Box sx={{ width: '100%', position: 'fixed', zIndex: 999 }}>
                <UpBar />
            </Box>

            <Typography variant="h3" sx={{ fontSize: '2.3rem', color: '#f5bb0f', fontFamily: 'Luckiest Guy, serif', mb: 1, mt:15,  ml:2, mr:2, display: 'flex', flexDirection: 'column', alignItems: 'center',  }}>
            {loading ? "Your gamified campaign is in the AI oven."
              : campaignStatus === 'success' ? "Abrakadabra. Let's level up your marketing game."
              : campaignStatus === 'error' ? "We had a problem! Please try later."
              : "Your new gamified campaign is a few clicks away"}

            </Typography>

            <Grid container spacing={2} sx={{ paddingTop: '104px', width: { xs: '100%', md: '80%', lg:'70%' }, margin: { md: 'auto' } }}>
                <Grid item xs={12} md={6}>
                {campaignStatus === 'success' ? (


                  <Card sx={{ mb: 15 }}>
              <CardContent>
              <Typography variant="h3" sx={{ fontSize: '2.3rem', color: '#ed37db', fontFamily: 'Luckiest Guy, serif', mb: 1, ml:2, mr:2, display: 'flex', flexDirection: 'column', alignItems: 'center',  }}>
                last minute settings
              </Typography>
            
              <Divider>Image Assets</Divider>

        <Grid container spacing={2}>

                {images.map((url, index) => (

        <Grid item xs={4} sm={4} md={4} key={index}
        onMouseEnter={() => setHoverIndex(index)}
        onMouseLeave={() => setHoverIndex(null)}>
          <div style={{ position: 'relative', padding: '10px' }}>
            <img
              src={url}
              alt={`Campaign Image ${index}, ${url}`}
              style={{
                width: '100%',
                height: '100%',
                display: 'block',
                border: '1px solid black',
                borderRadius: '25px',
                transition: 'transform 0.3s ease-in-out',
                transform: hoverIndex === index ? 'scale(1.1)' : 'scale(1)'
              }}
            />
            <Button
              variant="contained"
              onClick={() => handleImageDialogOpen(index)} 
              sx={{
                position: 'absolute',
                bottom: '10px',
                left: '10px',
                width: { xs: '50px', sm: '70px', md: '30px', lg: '30px' },
                fontSize: { xs: '0.6rem', sm: '0.8rem', md: '0.6rem', lg: '0.6rem' },
                backgroundColor: '#0fbbd9',
                '&:hover': {
                  backgroundColor: '#0a8ba4',
                }
              }}
            >
              change
            </Button>
          </div>
        </Grid>
      ))}
              </Grid>

              <Divider sx={{ mb: 2 }}>3D texts titles</Divider>



      <Grid container spacing={2}>
      {threeDtexst.map((url, index) => (
        <Grid container item xs={12} spacing={2} key={index}>
          <Grid item xs={6} >
            <div style={{ position: 'relative', padding: '10px' }}>
              <img
                src={url}
                alt={`Campaign Image ${index}, ${url}`}
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'block',
                  borderRadius: '25px',
                  transition: 'transform 0.3s ease-in-out',
                  transform: threeDtexts[index] ? 'scale(1.1)' : 'scale(1)'
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <TextField
              label={`Change Text`}
              variant="outlined"
              value={threeDtexts[index]}
              onChange={(e) => handle3DTextChange(index, e.target.value)}
              fullWidth
            />


                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handle3DtextChangeClick(index)}
                  style={{ marginTop: '10px', position: 'relative',              
                  backgroundColor: '#0fbbd9',
                  '&:hover': {
                    backgroundColor: '#0a8ba4',
                  } }}
                  disabled={loading3DTextIndex === index} // Disable button while loading
                >
                  {loading3DTextIndex === index ? (
                    <>
                      Change 
                      <CircularProgress size={20} style={{ marginLeft: '10px' }} />
                    </>
                  ) : 'Change'}
                </Button>


          </Grid>


          {/* <Divider sx={{ mb: 2 }}>3D texts titles</Divider> */}


        </Grid>


      ))}
    </Grid>

    <Divider sx={{ mb: 2, mt: 2 }}>Texts</Divider>

    <Grid container spacing={2}>
      {twoDtexts.map((text, index) => (
        <Grid container item xs={12} spacing={2} key={index}>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <TextField
              label={`Change Text`}
              variant="outlined"
              value={twoDtexts[index]}
              onChange={(e) => handle2DTextChange(index, e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handle2DtextChangeClick(index)}
              style={{ marginTop: '10px', position: 'relative', backgroundColor: '#0fbbd9', '&:hover': { backgroundColor: '#0a8ba4' } }}
              disabled={loading2DTextIndex === index}
            >
              {loading2DTextIndex === index ? (
                <>
                  Change 
                  <CircularProgress size={20} style={{ marginLeft: '10px' }} />
                </>
              ) : 'Change'}
            </Button>
          </Grid>
        </Grid>
      ))}
    </Grid>


    <Divider sx={{ mb: 2, mt: 2 }}>Form</Divider>





    <Grid container spacing={2}>
  <Grid item xs={12} sm={4}>
    <Button
      variant="contained"
      fullWidth
      onClick={() => handleNameClick(!nameClicked)}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: nameClicked ? 'green' : '#FFCCCB',
      }}
    >
      NAME
      {nameClicked ? <CheckCircleOutlineIcon /> : <CancelIcon />}
    </Button>
  </Grid>
  <Grid item xs={12} sm={4}>
    <Button
      variant="contained"
      fullWidth
      onClick={() => handleEmailClick(!emailClicked)}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: emailClicked ? 'green' : '#FFCCCB',
      }}
    >
      EMAIL
      {emailClicked ? <CheckCircleOutlineIcon /> : <CancelIcon />}
    </Button>
  </Grid>
  <Grid item xs={12} sm={4}>
    <Button
      variant="contained"
      fullWidth
      onClick={() => handlePhoneClick(!phoneClicked)}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: phoneClicked ? 'green' : '#FFCCCB',
      }}
    >
      PHONE
      {phoneClicked ? <CheckCircleOutlineIcon /> : <CancelIcon />}
    </Button>
  </Grid>
</Grid>





          <Grid item xs={12} sx={{ mt: 4 }}>
            <TextField
              label="Need a consent statement? write it here."
              variant="outlined"
              fullWidth
              value={legalStatement}
              onChange={(e) => setLegalStatement(e.target.value)}
            />

            <Typography 
                  sx={{ 
                    color: 'gray', 
                    fontSize: '0.75rem'  // This is 14px, you can adjust it as needed
                  }}
                >
                  To add a link, use the following format: wrap the text with asterisks (*) and enclose the URL with hash symbols (#). For example: *Terms and Conditions* #www.MyTerms.com#
                </Typography>

          <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLegalStatementSubmit}
                  fullWidth
                  sx={{
                    mt: 1,
                    position: 'relative',
                    backgroundColor: '#0fbbd9',
                    '&:hover': { backgroundColor: '#0a8ba4' }
                  }}
                >
                  Submit
                </Button>



            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                          <Checkbox
                checked={checkMark}
                onChange={handleCheckMarkChange} // Update to use the new function
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography variant="body1">Need a checkbox next to it?</Typography>
            </div>
          </Grid>




          <Divider sx={{ mb: 2, mt: 5 }}>Incentive Delivery Options</Divider>
<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mb: 2
  }}
>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
    }}
  >
    <Typography>Coupon</Typography>


    <Switch
      checked={couponEnabled}
      onChange={handleCouponToggle}
      color="primary"
      sx={{
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: '#ff69b4',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: '#ff69b4',
        },
      }}
    />


  </Box>

  <Divider />

  <Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    mb: 2
  }}
>
  <TextField
    label="Coupon Code"
    value={couponValue}
    onChange={handleCouponChange}
    variant="outlined"
    disabled={!couponEnabled}
    sx={{ marginBottom: 1 }}
  />

  <Button
    variant="contained"
    color="primary"
    onClick={handleCouponSubmit}
    disabled={!couponEnabled}
    sx={{ 
      backgroundColor: '#0fbbd9' 
    }}
  >
    Submit
  </Button>
</Box>

<Box
  sx={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    mb: 2
  }}
>
  <TextField
    label="Coupon Details"
    value={couponDetails}
    onChange={handleCouponDetailsChange}
    variant="outlined"
    disabled={!couponEnabled}
    sx={{ marginBottom: 1 }}
  />

  <Button
    variant="contained"
    color="primary"
    onClick={handleCouponDetailsSubmit}
    disabled={!couponEnabled}
    sx={{ 
      backgroundColor: '#0fbbd9' 
    }}
  >
    Submit
  </Button>
</Box>

  {(nameClicked || emailClicked || phoneClicked) && (
    <>
      <Typography variant="body1">When to reveal the Coupon? Before or after the form submission?</Typography>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleChangeAlignment}
        aria-label="Incentive reveal time"
        sx={{ mb: 2 }}
        disabled={!couponEnabled}
      >
        <ToggleButton
          value="Before"
          sx={{
            '&.Mui-selected': {
              fontWeight: 'bold',
              color: 'primary.main',
            },
          }}
          disabled={!couponEnabled}
        >
          Before
        </ToggleButton>
        <ToggleButton
          value="After"
          sx={{
            '&.Mui-selected': {
              fontWeight: 'bold',
              color: 'primary.main',
            },
          }}
          disabled={!couponEnabled}
        >
          After
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )}
</Box>

<Divider />

<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, gap: 2 }}>
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
    <Typography>Button Link</Typography>
    <Switch
      checked={buttonLinkEnabled}
      onChange={handleButtonLinkToggle}
      color="primary"
      sx={{
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: '#ff69b4',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: '#ff69b4',
        },
      }}
    />

  </Box>

  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <TextField
      label="Button Text"
      value={buttonText}
      disabled={!buttonLinkEnabled}
      onChange={handleButtonTextChange}
      variant="outlined"
      fullWidth
    />
    <Button
      variant="contained"
      color="primary"
      onClick={handleButtonTextSubmit}
      disabled={!buttonLinkEnabled}
      sx={{ ml: 2,
        backgroundColor: '#0fbbd9',
      }}
    >
      Submit
    </Button>
  </Box>

  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, width: '100%' }}>
    <TextField
      label="Actual Link"
      value={actualLink}
      disabled={!buttonLinkEnabled}
      onChange={handleActualLinkChange}
      variant="outlined"
      fullWidth
    />
    <Button
      variant="contained"
      color="primary"
      onClick={handleActualLinkSubmit}
      disabled={!buttonLinkEnabled}
      sx={{ ml: 2,
        backgroundColor: '#0fbbd9',
      }}
    >
      Submit
    </Button>
  </Box>


  {(nameClicked || emailClicked || phoneClicked) && (
    <>
      <Typography variant="body1">When to reveal the button link? Before or after form submission?</Typography>
      <ToggleButtonGroup
        color="primary"
        value={buttonLinkRevealTime}
        exclusive
        onChange={handleButtonLinkRevealTimeChange}
        aria-label="Button Link Reveal Time"
        sx={{ mb: 2 }}
        disabled={!buttonLinkEnabled}
      >
        <ToggleButton value="Before" sx={{ '&.Mui-selected': { fontWeight: 'bold', color: 'primary.main' } }} disabled={!buttonLinkEnabled}>
          Before
        </ToggleButton>
        <ToggleButton value="After" sx={{ '&.Mui-selected': { fontWeight: 'bold', color: 'primary.main' } }} disabled={!buttonLinkEnabled}>
          After
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )}
</Box>











         

              </CardContent>
            </Card>

          ) : (
                    <Card sx={{ fontFamily: "'Luckiest Guy', serif", mb:20}}>
                        <CardContent>
                        <Typography 
                            variant="h3" 
                            sx={{
                              fontFamily: "'Luckiest Guy', serif",
                              mb: 2,
                              color: '#d40bc3',
                              fontSize: {
                                xs: '1.5rem',  // extra-small devices
                                sm: '2rem',    // small devices
                                md: '2.5rem',  // medium devices
                                lg: '3rem',    // large devices
                                xl: '3.5rem'   // extra-large devices
                              }
                            }}
                          >
                            What for
                          </Typography>

                          <Grid container spacing={1}>
                          {buttonLabels.map((label, index) => {
  const isComingSoon = label === 'Social media post/ad' || label === 'SMS/ Whatsapp message';
  return (
    <Grid item xs={4} key={index} sx={{ position: 'relative' }}>
      <Button
        variant="contained"
        fullWidth
        disabled={isComingSoon}  // Disable the button
        sx={{
          borderRadius: '25px',
          bgcolor: isComingSoon ? '#cccccc' : selectedButton === index ? '#0f0' : '#1ed8e6',
          color: isComingSoon ? '#999999' : '#ffffff',
        }}
        onClick={() => handleButtonToggle(index)}
      >
        {label}
        {isComingSoon && (
          <div
            style={{
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              backgroundColor: '#ff0000',
              color: '#ffffff',
              padding: '2px 8px',
              borderRadius: '4px',
              transform: 'rotate(20deg)',
              fontSize: '10px',
            }}
          >
            Coming Soon
          </div>
        )}
      </Button>
    </Grid>
  );
})}
    </Grid>


                            <Typography 
                                variant="h3" 
                                sx={{
                                  fontFamily: "'Luckiest Guy', serif",
                                  mb: 2,
                                  mt: 8,
                                  color: '#d40bc3',
                                  fontSize: {
                                    xs: '1.5rem',  // extra-small devices
                                    sm: '2rem',    // small devices
                                    md: '2.5rem',  // medium devices
                                    lg: '3rem',    // large devices
                                    xl: '3.5rem'   // extra-large devices
                                  }
                                }}
                              >
                                Your desired campaign
                              </Typography>

                              <Typography
                                  variant="h6"
                                  sx={{
                                    fontFamily: "'Luckiest Guy', serif",
                                    mb: 2,
                                    color: 'gray',
                                    fontSize: {
                                      xs: '0.75rem',  // extra-small devices
                                      sm: '0.875rem', // small devices
                                      md: '1rem',     // medium devices
                                      lg: '1.125rem', // large devices
                                      xl: '1.25rem'   // extra-large devices
                                    }
                                  }}
                                >
                                  examples:
                                </Typography>

                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontFamily: 'Quicksand, serif',
                                    mb: 2,
                                    color: 'gray',
                                    fontSize: {
                                      xs: '0.75rem',  // extra-small devices
                                      sm: '0.875rem', // small devices
                                      md: '1rem',     // medium devices
                                      lg: '1.125rem', // large devices
                                      xl: '1.25rem'   // extra-large devices
                                    }
                                  }}
                                >
                                  --"The campaign should target new customers and give them a 10% discount coupon if they leave their email"
                                </Typography>

                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontFamily: 'Quicksand, serif',
                                    mb: 2,
                                    color: 'gray',
                                    fontSize: {
                                      xs: '0.75rem',  // extra-small devices
                                      sm: '0.875rem', // small devices
                                      md: '1rem',     // medium devices
                                      lg: '1.125rem', // large devices
                                      xl: '1.25rem'   // extra-large devices
                                    }
                                  }}
                                >
                                  --"give away a free donut with any purchase"
                                </Typography>

                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontFamily: 'Quicksand, serif',
                                    mb: 2,
                                    color: 'gray',
                                    fontSize: {
                                      xs: '0.75rem',  // extra-small devices
                                      sm: '0.875rem', // small devices
                                      md: '1rem',     // medium devices
                                      lg: '1.125rem', // large devices
                                      xl: '1.25rem'   // extra-large devices
                                    }
                                  }}
                                >
                                  --"boost sales of our new product - 'Yummi Lemonade', give away 35% off when buying on the website for more than $50"
                                </Typography>


                            <TextField
                                  multiline
                                  rows={7}
                                  fullWidth
                                  variant="outlined"
                                  label="Campaign Description - keep it simple"  // Shorter label text
                                  placeholder="Describe your campaign in detail, including your goals, strategies, and expected outcomes. This information will help us tailor our services to better meet your needs."  // Long placeholder text
                                  value={campaignDesc}
                                  onChange={handleCampaignDescChange}
                                  sx={{
                                    mb: 2,
                                    '& .MuiOutlinedInput-root': {
                                      backgroundColor: '#f0f0f0',
                                      fontFamily: 'Quicksand, serif',
                                      fontSize: '1.25rem',
                                    },
                                    '& .MuiInputLabel-root': {
                                      fontFamily: 'Quicksand, serif',
                                      fontSize: '1.25rem',
                                    }
                                  }}
                                  InputProps={{
                                    sx: {
                                      '&::placeholder': {
                                        opacity: 1,  // Make placeholder fully visible
                                        fontStyle: 'italic',  // Optional style
                                      }
                                    }
                                  }}
                                />

                                <Typography 
                                  variant="h3" 
                                  sx={{
                                    fontFamily: "'Luckiest Guy', serif", 
                                    mb: 2, 
                                    mt: 8,
                                    color: '#d40bc3',
                                    fontSize: {
                                      xs: '1.5rem',  // extra-small devices
                                      sm: '2rem',    // small devices
                                      md: '2.5rem',  // medium devices
                                      lg: '3rem',    // large devices
                                      xl: '3.5rem'   // extra-large devices
                                    }
                                  }}
                                >
                                  Upload product image*
                                </Typography>

                                <Typography
                                    variant="h5"
                                    sx={{
                                      fontFamily: "'Luckiest Guy', serif",
                                      mb: 2,
                                      color: 'gray',
                                      fontSize: {
                                        xs: '1rem',      // extra-small devices
                                        sm: '1.125rem',  // small devices
                                        md: '1.25rem',   // medium devices
                                        lg: '1.5rem',    // large devices
                                        xl: '1.75rem'    // extra-large devices
                                      }
                                    }}
                                  >
                                    *optional. If your campaign involves a product (for example, if you're offering a discount on a specific product), upload its image and description.
                                  </Typography>


                             {/* New product image upload */}
                             <Typography variant="h6" sx={{ fontFamily: "'Luckiest Guy', serif", mb: 2, color: '#d40bc3' }}>
                                Product Image
                            </Typography>
                            <Button
                            variant="contained"
                            component="label"
                            sx={{
                                width: '50%',            // Set width to 50% of the container
                                mb: 2,                   // Margin bottom 2
                                display: 'block',        // Makes the button a block element to take width properly
                                py: 2,                   // Increase padding-top and padding-bottom for a fatter appearance
                                borderRadius: '25px',    // Set border radius to 25px
                                fontFamily: "'Luckiest Guy', serif",  // Set custom font family
                                textAlign: 'center'      // Center align the text inside the button
                            }}
                            >
                            Upload Image
                            <input type="file" hidden onChange={handleImageChange} />
                            </Button>

                            {productImage && (
                              <Box sx={{ mt: 2, mb: 2 }}>
                                  <Typography variant="h6" sx={{ fontFamily: "'Luckiest Guy', serif", color: '#d40bc3' }}>
                                      Preview:
                                  </Typography>
                                  <img
                                      src={URL.createObjectURL(productImage)}
                                      alt="Product"
                                      style={{
                                          width: '100%',    // Full width of the container
                                          maxHeight: '200px', // Limiting the height to 200px
                                          objectFit: 'contain', // Keep the aspect ratio of the image
                                          borderRadius: '10px' // Optional: rounding the corners of the image
                                      }}
                                  />


                                <Typography variant="h6" sx={{ fontFamily: "'Luckiest Guy', serif", color: '#d40bc3' }}>
                                      remove background? 
                                  </Typography>

                                  <Checkbox
                                    checked={isChecked} // Whether the checkbox is checked or not
                                    onChange={handleCheckboxChange} // Function to handle changes
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{ color: 'pink' }} // You can customize the color using the theme
                                />

                              </Box>

                          )}

                              <Typography
                                variant="h6"
                                sx={{
                                  fontFamily: "'Luckiest Guy', serif",
                                  mt: 5,
                                  color: '#d40bc3',
                                  fontSize: {
                                    xs: '0.75rem',  // extra-small devices
                                    sm: '0.875rem', // small devices
                                    md: '1rem',     // medium devices
                                    lg: '1.125rem', // large devices
                                    xl: '1.25rem'   // extra-large devices
                                  }
                                }}
                              >
                                Product description
                              </Typography>

                              <Typography
                                variant="h6"
                                sx={{
                                  fontFamily: "'Luckiest Guy', serif",
                                  color: 'gray',
                                  fontSize: {
                                    xs: '0.75rem',  // extra-small devices
                                    sm: '0.875rem', // small devices
                                    md: '1rem',     // medium devices
                                    lg: '1.125rem', // large devices
                                    xl: '1.25rem'   // extra-large devices
                                  }
                                }}
                              >
                                examples:
                              </Typography>

                              <Typography
                                variant="h6"
                                sx={{
                                  fontFamily: 'Quicksand, serif',
                                  mb: 2,
                                  color: 'gray',
                                  fontSize: {
                                    xs: '0.75rem',  // extra-small devices
                                    sm: '0.875rem', // small devices
                                    md: '1rem',     // medium devices
                                    lg: '1.125rem', // large devices
                                    xl: '1.25rem'   // extra-large devices
                                  }
                                }}
                              >
                                --"Kids Outdoor Automatic Unicorn Bubble Blowing Machine"
                              </Typography>

                              <Typography
                                variant="h6"
                                sx={{
                                  fontFamily: 'Quicksand, serif',
                                  mb: 2,
                                  color: 'gray',
                                  fontSize: {
                                    xs: '0.75rem',  // extra-small devices
                                    sm: '0.875rem', // small devices
                                    md: '1rem',     // medium devices
                                    lg: '1.125rem', // large devices
                                    xl: '1.25rem'   // extra-large devices
                                  }
                                }}
                              >
                                --"strawberry cupcake"
                              </Typography>



                            <TextField
                                multiline
                                rows={2}
                                fullWidth
                                variant="outlined"
                                label="Product Description - keep it simple"
                                placeholder="Enter the product description here"
                                value={productDesc}
                                onChange={handleProductDescChange}
                                sx={{
                                    mb: 2,
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: '#f0f0f0',
                                        fontFamily: 'Quicksand, serif',
                                        fontSize: '1.25rem',
                                    }
                                }}
                            />

                          <Typography variant="h6" sx={{ fontFamily: "'Luckiest Guy', serif", mb: 2, color: '#d40bc3' }}>
                                choose your style
                            </Typography>

        <Grid container spacing={2}>
          {Array.from({ length: 4 }).map((_, index) => (
            <Grid item xs={3} key={index}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleStyleButtonClick(index)}
                sx={{
                  paddingTop: '100%',
                  backgroundColor: 'transparent', // Set background color to transparent
                  borderRadius: '25px',
                  position: 'relative',
                  transform: activeVersionButton === index ? 'scale(1.05)' : 'scale(1)',
                  transition: 'transform 0.3s ease-in-out, border-color 0.3s ease-in-out',
                  border: activeVersionButton === index ? '4px solid #2596be' : '2px solid transparent', // Gold border when active
                  '&:hover': {
                    transform: 'scale(1.05)',
                    borderColor: '#2596be', // Gold border on hover
                    backgroundColor: 'transparent', // Set background color to transparent

                  },
                }}
              >
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${buttoneImages[index]})`,
          backgroundSize: 'cover',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent' // Ensure the background inside the div is also transparent

        }}>
        </div>
      </Button>
    </Grid>
  ))}
</Grid>




                        <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            background: 'linear-gradient(45deg, #ff6900 30%, #ffba00 90%)',
                            fontFamily: "'Luckiest Guy', serif",
                            color: 'white',
                            fontSize: '1.35rem',
                            borderRadius: '25px',
                            py: 1.7,
                            my: 2
                        }}
                        onClick={handleSubmit}
                        disabled={isGenerateDisabled}  // Control the disabled state based on form input

                    >
                        Generate Campaign
                    </Button>


                    {loading && (
                      <Box sx={{
                            display: 'flex',       // Ensures the box is a flex container
                            justifyContent: 'center', // Centers children horizontally in the container
                            alignItems: 'center',    // Centers children vertically in the container
                          }}>
                            <CircularProgress />
                            <Typography variant="body2" sx={{ mt: 2 }}>Generating...</Typography>
                          </Box>
                      )}

                        </CardContent>
                    </Card>
          )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card>
                    <CardContent>



                    <Typography 
                  variant="h3" 
                  sx={{
                    fontFamily: "'Luckiest Guy', serif", 
                    mb: 2, 
                    color: '#1ed8e6',
                    fontSize: {
                      xs: '1.5rem',  // extra-small devices
                      sm: '2rem',    // small devices
                      md: '2.5rem',  // medium devices
                      lg: '3rem',    // large devices
                      xl: '3.5rem'   // extra-large devices
                    }
                  }}
                >
 {loading ? "Your gamified campaign is in the AI oven. " :
        campaignStatus === 'success' ? "Your gamified campaign is ready!" :
        campaignStatus === 'error' ? "We had a problem! Please try later." :
        "Your gamified campaign is waiting for you to create it!"}

                     </Typography>
    


        {loading && (
        <Typography variant="h5" sx={{ fontFamily: "'Luckiest Guy', serif", color: '#1ed8e6', fontSize: {
          xs: '1rem',  // extra-small devices
          sm: '1.5rem',    // small devices
          md: '2rem',  // medium devices
          lg: '2rem',    // large devices
          xl: '2.5rem'   // extra-large devices
        } }}>
          1 minute on ave
        </Typography>
      )}

        




{iframeUrl ? (
  <Box
  sx={{
    width: '100%',
    paddingTop: '177.78%', // 9:16 aspect ratio
    border: 'none',
    borderRadius: '25px',
    position: 'relative',
    overflow: 'hidden',
  }}
>
  <iframe
    id="campaignIframe"
    src={iframeUrl}
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 'none',
      borderRadius: '25px',
    }}
    title="Marketing Campaign Details"
  ></iframe>

  <Tooltip title="Show QR">
    <IconButton onClick={ShowQR} sx={{ position: 'absolute', top: 5, right: 55, color: 'white' }}>
      <QrCode2Icon />
    </IconButton>
  </Tooltip>

  <Tooltip title="Open Fullscreen">
    <IconButton onClick={handleOpenDialog} sx={{ position: 'absolute', top: 5, right: 105, color: 'white' }}>
      <OpenInFullIcon />
    </IconButton>
  </Tooltip>

  <Tooltip title="Restart">
    <IconButton onClick={refreshIFrame} sx={{ position: 'absolute', top: 5, right: 155, color: 'white' }}>
      <RestartAltIcon />
    </IconButton>
  </Tooltip>

  <Tooltip title="Open in New Tab">
    <IconButton onClick={() => openInNewTab(iframeUrl)} sx={{ position: 'absolute', top: 5, right: 5, color: 'white' }}>
      <OpenInBrowserIcon />
    </IconButton>
  </Tooltip>
</Box>

) : (
  <Box sx={{
    width: '100%',
    height: '600px',
    backgroundColor: '#c4c4c4',
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',  // Centers horizontally
    alignItems: 'center',       // Centers vertically
    borderRadius: '25px'        // Rounded corners
  }}>
    {animationData ? (
      <Lottie 
        options={defaultOptions} 
        height={600}  // Adjust height as necessary
        width={400}   // Adjust width as necessary
        isStopped={!animationData}
        isPaused={!animationData}
      />
    ) : (
      <span>Loading animation...</span>  // Placeholder text while loading
    )}
  </Box>
)}




{iframeUrl && (
  <>
    {textCopyWrite && (
      <Card sx={{ mt: 2, p: 2, backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontFamily: "Quicksand, serif", 
            color: '#1ed8e6', 
            display: 'flex', 
            alignItems: 'center' 
          }}
        >
          AI suggests this text to be included next to your {EndUse} campaign's link 
        </Typography>
        <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', p: 2, mt: 2 }}>
          <Typography 
            variant="body1" 
            sx={{ 
              fontFamily: "Quicksand, serif", 
              color: '#333' 
            }}
          >
            {textCopyWrite}
          </Typography>
        </Box>
      </Card>
    )}



{isFormDataLoaded && EndUse !== 'E-commerce pop-up' && (
  <Card sx={{ mt: 4, p: 2, backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: 3 }}>
    <Typography 
      variant="h7" 
      sx={{ 
        fontFamily: "Quicksand, serif", 
        color: '#1ed8e6',
        mb: 5 
      }}
    >
      It is suggested to add this video to your {EndUse} to increase entries for your campaign.
    </Typography>

    {videoLoading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2, fontFamily: "'Luckiest Guy', serif", color: '#1ed8e6' }}>
          Generating video...
        </Typography>
      </Box>
    ) : (
      <Box
        sx={{
          mt: 5, 
          position: 'relative',
          width: '100%',
          paddingTop: '177.78%', // 9:16 aspect ratio
          overflow: 'hidden',
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          mx: 'auto', // Centers the video horizontally
        }}
      >
        <video 
          controls 
          src={videoSrc}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            objectFit: 'cover', // Ensure the video covers the entire area without distortion
          }}
        />
      </Box>
    )}

    <Button
      variant="contained"
      color="primary"
      fullWidth
      sx={{ 
        mt: 2, 
        fontFamily: "'Luckiest Guy', serif", 
        backgroundColor: '#FE6B8B', 
        '&:hover': { backgroundColor: '#d40bc3' }, 
        borderRadius: 25 
      }}
      onClick={async () => {
        const videoUrl = `https://gamifiedmarketing-pullzone.b-cdn.net/brands/${currentBrand?.id}/${campaignID}/video_preview.mp4`;
        const response = await fetch(videoUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'video_preview.mp4';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Clean up the URL object
      }}
    >
      Download Video
    </Button>
  </Card>
)}





{isFormDataLoaded ? (
      <Grid container spacing={2} sx={{ paddingTop: '104px', width: { xs: '100%', md: '100%', lg: '100%' }, margin: { md: 'auto' } }}>
        <Grid item xs={12} md={12}>
          {EndUse && EndUse === 'E-commerce pop-up' && (
            <Box sx={{ mt: 3, backgroundColor: '#f5f5f5', p: 2, borderRadius: 2, boxShadow: 3 }}>
              <Typography variant="h6" sx={{ fontFamily: "Quicksand, serif", color: '#1ed8e6' }}>
                Embed this snippet on your website:
              </Typography>
              <Box sx={{ mt: 2, p: 2, backgroundColor: '#2e2e2e', borderRadius: 2, color: 'white', fontFamily: "monospace", position: 'relative', boxShadow: 1 }}>
                <pre style={{ margin: 0, maxHeight: '150px', overflow: 'auto', padding: '8px', borderRadius: '4px', backgroundColor: '#1a1a1a', lineHeight: '1.5' }}>
                  <code>
                    {`<iframe src="${shortURL}" width="1080px" height="1920px" style="border:none;"></iframe>`}
                  </code>
                </pre>
                <IconButton onClick={handleCopy} sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}>
                  {copied ? <CheckCircleIcon sx={{ color: '#1ed8e6' }} /> : <ContentCopyIcon />}
                </IconButton>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    ) : (null)}



    <Button
      variant="contained"
      color="primary"
      fullWidth
      sx={{ 
        mt: 3, 
        fontFamily: "'Luckiest Guy', serif", 
        backgroundColor: '#FF8E53', 
        '&:hover': { backgroundColor: '#FE6B8B' }, 
        borderRadius: 25 
      }}
      onClick={() => console.log('Campaign Activated!')} // Add your activation logic here
    >
      Activate
    </Button>

    <Typography 
      variant="body2" 
      sx={{ 
        mt: 1, 
        mb:15,
        fontFamily: "Quicksand, serif", 
        color: '#1ed8e6', 
        textAlign: 'center' 
      }}
    >
      By activating this campaign, you allow your customers to start interacting with it.
    </Typography>

  </>
)}





                <Dialog
                      open={qrDialogOpen}
                      onClose={() => setQrDialogOpen(false)}
                      sx={{
                        '& .MuiDialog-paper': {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          overflow: 'hidden',
                          borderRadius: '25px',
                          border: 'none',
                        },
                      }}
                    >
                      <DialogContent
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: qrUrl? 0: 10,
                          '& img': {
                            width: '100%',
                            height: 'auto',
                            borderRadius: '25px',
                            border: 'none',
                          },
                        }}
                      >
                      {qrUrl ? (
                        <img src={qrUrl} alt="QR Code" />
                      ) : (
                        <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '15vh', // Adjust height as needed
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "'Luckiest Guy', serif",
                            color: '#1ed8e6',
                            fontSize: {
                              xs: '1rem',  // extra-small devices
                              sm: '1.5rem',    // small devices
                              md: '2rem',  // medium devices
                              lg: '2rem',    // large devices
                              xl: '2.5rem'   // extra-large devices
                            },
                          }}
                        >
                          loading your QR
                        </Typography>
                        <CircularProgress />
                      </Box>

                      )}

                    </DialogContent>
                    </Dialog>


          <Dialog open={openDialog} onClose={handleCloseDialog} axWidth="md"
              fullWidth
              sx={{
                  '& .MuiPaper-root': {
                      width: '100%',
                      height: 'auto',
                      maxWidth: '95vw',
                      maxHeight: '90vh',
                      m: 2,
                      borderRadius: '25px',
                      overflow: 'hidden',

                      '@media (min-width: 1300px)': {
                          width: '800px',
                          height: '1500px',
                          m: 0
                      },

                      '@media (max-width: 1300px)': {
                          width: '60vw',
                          height: '85vh',
                          m: 0
                      },

                      '@media (max-width: 1000px)': {
                          width: '70vw',
                          height: '95vh',
                          m: 0
                      },
                      '@media (max-width: 600px)': {
                          width: '95vw',
                          height: '90vh',
                          m: 0
                      },
                  }
              }}
              >
                  <DialogContent sx={{ padding: 0, overflow: 'hidden', position: 'relative' }}>
                    <IconButton onClick={handleCloseDialog} sx={{ position: 'absolute', top: 8, right: 8 }}>
                      <FullscreenExitIcon />
                    </IconButton>
                    <iframe
                      src={iframeUrl}
                      style={{ width: '100%', height: '100%', borderRadius: '25px', border: 'none' }}
                      title="Campaign Statistics"
                    ></iframe>
                  </DialogContent>
                </Dialog>
    </CardContent>
          </Card>
          
          <Dialog open={imageDialogOpen} onClose={handleImageDialogClose}>
  <DialogContent>
    {isGenerating ? (
      <>
        <Typography variant="h5" sx={{ fontFamily: "'Luckiest Guy', serif", color: '#1ed8e6', fontSize: {
          xs: '1rem',  // extra-small devices
          sm: '1.5rem',    // small devices
          md: '2rem',  // medium devices
          lg: '2rem',    // large devices
          xl: '2.5rem'   // extra-large devices
        } }}>
          AI generates you a beautiful alternative
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#1ed8e6', marginBottom: '20px' }}>
          7 seconds on ave
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      </>
    ) : (
      <>
      <Typography variant="h5" sx={{
        fontFamily: "'Luckiest Guy', serif",
        color: '#1ed8e6',
        fontSize: {
          xs: '1rem',   // extra-small devices
          sm: '1.5rem', // small devices
          md: '2rem',   // medium devices
          lg: '2rem',   // large devices
          xl: '2.5rem'  // extra-large devices
        }
      }}>
        {isGeneratingVersion ? (
          <Stack direction="column" alignItems="center" spacing={1}>
            <div>Loading your final pick!</div>
            <CircularProgress size={24} sx={{ color: 'primary' }} />
          </Stack>
        ) : (newImagePreview ? 'Choose a version' : 'Change image asset')}
      </Typography>






      {newImagePreview ? (
  <Grid container spacing={2}>
    {newImagePreview.map((url, index) => (
      <Grid item xs={6} key={index}>
        <Box sx={{ position: 'relative', width: '100%', height: '150px' }}>
          <img src={url} alt={`Generated Image ${index + 1}`} style={{ width: '100%', height: '100%', borderRadius: '8px' }} />
          <Button 
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(255, 255, 255, 0)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.7)'
              }
            }}
            onClick={() => handleImageVersionSelect(index + 1)}
          >
            Use Image {index + 1}
          </Button>
        </Box>
      </Grid>
    ))}
  </Grid>
) : (
  <>
    <img src={images[selectedImageIndex]} alt={`Expanded Campaign Image ${selectedImageIndex}`} style={{ width: '100%' }} />
    <TextField
      label="Generate a new AI asset"
      variant="outlined"
      fullWidth
      margin="normal"
      value={textInput}
      onChange={handleInputChange}
    />
    <Grid container spacing={2}>
      {Object.entries(optionalImages).map(([key, description], index) => (
        <Grid item key={key}>
          <Button
            variant="contained"
            onClick={() => handleImageSelection(description)}
            sx={{
              borderRadius: 25,
              padding: '8px 16px',
              minWidth: '30px',
              fontSize: {
                xs: '0.6rem',  // for extra-small screens
                sm: '0.6rem', // for small screens
                md: '1rem',     // for medium screens
                lg: '1rem'  // for large screens
              },
              backgroundColor: selectedImageDescription === description ? '#17c0d1' : '#1ed8e6',
              '&:hover': {
                backgroundColor: '#17c0d1'
              }
            }}
          >
            {description}
          </Button>
        </Grid>
      ))}
    </Grid>
    <Button
      variant="contained"
      color="primary"
      fullWidth
      sx={{ borderRadius: 25, marginTop: '10px', fontFamily: "'Luckiest Guy', serif", backgroundImage: 'linear-gradient(45deg, #FF8E53, #FE6B8B)' }}
      disabled={generateDisabled}
      onClick={handleGenerate}
    >
      Generate
    </Button>

    <Divider sx={{ my: 2 }}>OR</Divider>
    <Button
      variant="contained"
      component="label"
      sx={{
        borderRadius: 25,
        marginTop: '10px',
        fontFamily: "'Luckiest Guy', serif",
        backgroundImage: 'linear-gradient(45deg, #d40fb3, #d40fb3)',
        width: '100%' // Adjust width as needed
      }}
    >
      Upload Your Own
      <input type="file" hidden onChange={handleUpload} />
    </Button>
    {userImagePreview && (
      <>
        <img src={userImagePreview} alt="User Uploaded" style={{ width: '100%', maxHeight: '300px', objectFit: 'contain', marginTop: '20px' }} />
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          sx={{
            mt: 2,
            fontFamily: "'Luckiest Guy', serif",
            backgroundColor: '#87dedc',
            '&:hover': { backgroundColor: '#5ab0ae' },
            borderRadius: 25 // Apply border-radius
          }}
          onClick={handleRemoveBackgroundUserImage}
        >
          {removingBackground ? <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} /> : null}
          Remove Background
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 2,
            fontFamily: "'Luckiest Guy', serif",
            backgroundColor: '#304ffe',
            '&:hover': { backgroundColor: '#536dfe' },
            borderRadius: 25 // Apply border-radius
          }}
          onClick={handleUseUserImage}
        >
          {UserUploadOwn ? <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} /> : null}
          Lets use it
        </Button>
      </>
    )}
  </>
)}

       
      </>
    )}
  </DialogContent>
</Dialog>

                </Grid>
            </Grid>
          </Box>
    );
};

export default NewCampaignEcommerce;
