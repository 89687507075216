import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';


import Divider from '@mui/material/Divider';

import GoogleIcon from '@mui/icons-material/Google';


import { createUserWithEmailAndPassword, onAuthStateChanged, signOut, updateProfile, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../firebase-config.js';

import { useUserContext } from '../App'; // Import the context here

import './styles.css'; // Create this file and import it


const provider = new GoogleAuthProvider();



const SignUp = ({ onSignUpClick }) => {
  const [registerEmail, setRegisterEmail] = React.useState("");
  const [registerName, setRegisterName] = React.useState("");
  const [registerPassword, setRegisterPassword] = React.useState("");
  const [user, setUser] = React.useState({});

  const navigate = useNavigate(); // Initialize useNavigate
  const { setUserDetails } = useUserContext(); // Use setUsername from context



  const loginGoogle = async () => {
    try {
      const user = await signInWithPopup(auth, provider);
      setUserDetails(user);
      navigate('/Onboarding');
    } catch (error) {
      console.log(error.message);
    }
  };



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const register = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
  
      await updateProfile(userCredential.user, {
        displayName: registerName
      });
      

      // Set the username before navigating
      setUserDetails(userCredential);
      
      console.log("assssssssss")
      navigate('/Onboarding'); // Redirect to '/Onboarding' after successful sign-up
    } catch (error) {
      console.log(error.message);
    }
  };

  const logout = async () => {
    await signOut(auth)
  };

  const handleEmailChange = (event) => {
    setRegisterEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setRegisterPassword(event.target.value);
  };

  const handleNameChange = (event) => {
    setRegisterName(event.target.value);
  };

  const pinkColor = "#ee01fd";

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '80vw', }}>
      <Box sx={{ textAlign: 'center', p: 3, width: 300,  boxShadow: '0 0 3px rgba(0, 0, 0, 1)', borderRadius: 10 }}>
        <Typography variant="h4" component="div" sx={{ width: "100%", mb: 4, color: pinkColor, fontFamily: 'Luckiest Guy, serif' }}>
          Sign Up
        </Typography>

        <TextField
          id="outlined-full-name"
          label="Full Name"
          variant="outlined"
          sx={{ width: "100%", mb: 2, fontFamily: 'Quicksand, serif' }}
          onChange={handleNameChange}
        />

        <TextField
          id="outlined-email"
          label="Email"
          variant="outlined"
          sx={{ width: "100%", mb: 2, fontFamily: 'Quicksand, serif' }}
          onChange={handleEmailChange}
        />

        <TextField
          id="outlined-password"
          label="Password"
          type="password"
          autoComplete="current-password"
          sx={{ width: "100%", mb: 1, fontFamily: 'Quicksand, serif' }}
          onChange={handlePasswordChange}
        />

        <Button variant="contained" sx={{ width: "100%", fontSize: "0.8rem", backgroundColor: pinkColor, fontFamily: 'Quicksand, serif' }} onClick={register}>Sign Up</Button>


        <Divider  style={{ color: 'black' }} >or</Divider>

        <Button
            variant="contained"
            sx={{
                width: "80%",
                fontSize: "0.8rem",
                backgroundColor: pinkColor,
                fontFamily: 'Quicksand, serif',
                marginTop: 4 // Adding margin-top of 4
            }}
            startIcon={<GoogleIcon />} // Adding the Google icon as the start icon
            onClick={loginGoogle}
        >
            Sign up with Google !
        </Button>

        <Typography variant="body2" sx={{ marginTop: 5, color: 'black', fontFamily: 'Quicksand, serif' }}>
          Have an account??
          <Link
            href="#"
            sx={{ color: '#ee01fd', fontFamily: 'Quicksand, serif', marginLeft: '10px' }}
            onClick={onSignUpClick}
          >
            Sign In! {user?.email}
          </Link>
        </Typography>
      </Box>
    </div>
  );
}

export default SignUp;
