import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Import CheckCircleOutlineIcon
import Checkbox from '@mui/material/Checkbox';

import { useNavigate } from 'react-router-dom';

import Onboarding1 from './Onboarding1';
import Onboarding2Company from './Onboarding2Company';
import Onboarding2Agency from './Onboarding2Agency';
import Onboarding3Company from './Onboarding3Company';

import { useUserContext } from "../App";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Tooltip from '@mui/material/Tooltip';

import './styles.css'; // Create this file and import itdddd

import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth();

const user = auth.currentUser;

function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}

const Onboarding = () => {
  const [showCircularProgress, setShowCircularProgress] = React.useState(false);

  const navigate = useNavigate(); // Correct use of useNavigate

  const [user_name, setUser_name] = React.useState(``);
  const [UID, setUID] = React.useState(``);
  const [user, setUser] = React.useState(null); // State to hold the user object

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        // No user is signed in, redirecting
        navigate('/login'); // Redirect to login or another appropriate route
      }
    });
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  useEffect(() => {
    // Function to fetch the first name from Firebase Authentication
    const fetchFirstName = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
              console.log("user-->>>>", user)

                const uid = user.uid;
                console.log(user.displayName);
                const firstName = user.displayName.split(' ')[0];
                setUser_name(`${firstName}`);
                setUID(uid);
                
                console.log("firstName", firstName)
                console.log("uid", uid)

            } else {
                console.log("NO USERREERERER");
            }
        });
    };
    fetchFirstName(); // Call the function to fetch the first name
}, [user]);

  const [logo, setLogo] = React.useState(null);
  const [logoFile, setLogoFile] = React.useState(null); // Add this to keep track of the actual file
  const [removeBackground, setRemoveBackground] = React.useState(false); // State to keep track of remove background checkbox

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file); // Save the file itself, not just its data URL
      const reader = new FileReader();
      reader.onload = () => {
        setLogo(reader.result); // Continue to use reader.result for preview purposes
      };
      reader.readAsDataURL(file);
    }
  };

  const [role, setRole] = React.useState('');
  const [brandType, setBrandType] = React.useState('');
  const [brandSelection, setBrandSelection] = React.useState('');
  const [websiteLink, setWebsiteLink] = React.useState('');
  const [validationMessage, setValidationMessage] = React.useState('');
  const [showValidationMessage, setShowValidationMessage] = React.useState(false);

  const handleRoleClick = (selectedRole) => {
    setRole(selectedRole === role ? '' : selectedRole);
  };

  const handleBrandSelection = (selection) => {
    setBrandSelection(selection);
  };
  
  const handleFinish = () => {
    let message = '';
    if (!brandSelection) {
      message += 'Brand selection, ';
    }
    if (brandSelection && !websiteLink) {
      message += 'Website link, ';
    }
    if (brandSelection && !logo) {
      message += 'Logo, ';
    }
    if (!role) {
      message += 'Role, ';
    }
    if (message) {
      message = message.slice(0, -2) + ' -> required.';
      setValidationMessage(message);
      setShowValidationMessage(true);
    } else {
      // All required fields are filled. Proceeding...
      setShowValidationMessage(false);
      setValidationMessage('');
  
      setShowCircularProgress(true);

      const firstName = user.displayName.split(' ')[0];
      const lastName = user.displayName.split(' ')[1] || '';
      const email = user.email;
      // Create a FormData object and append the data to it
      const formData = new FormData();

      console.log("user-->>>>", user)
      formData.append('user_name', user_name);
      formData.append('uid', UID);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('userEmail', email);

      formData.append('role', role);
      formData.append('brandSelection', brandSelection);
      formData.append('logo', logoFile);
      formData.append('websiteLink', websiteLink);
      formData.append('removeBackground', removeBackground);
  
      // Send form data to the specified endpoint
      fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: {
          // 'Content-Type': 'application/json', // Specify content type
          'react-app': 'Onboarding' // Add custom header
      },
        body: formData
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Handle response
        console.log('Data sent successfully!');
        navigate('/CompanyHome');
        // You can also navigate to the next page here if needed
      })
      .catch(error => {
        console.error('There was a problem with your fetch operation:', error);
      });
    }
  };
  
  if (!user) return null; // Or some loading indicator

  return (
    <div style={{ background: "linear-gradient(to right, #eb0cc5, #00e1ff)" , minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
      <Box sx={{  margin: '0 auto', mt: 4, mb: 4, borderRadius: '25px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: { xs: '90%', sm: '80%', md: '60%', lg: '50%' }, backgroundImage: 'linear-gradient(to bottom, #f31d6f, #8c079e)'}}>
        <Typography variant="h3" sx={{ fontSize: { xs: '30px', sm: '34px', md: '40px' }, color: '#ffffff', fontFamily: 'Luckiest Guy, serif', mb: 2, mt: 3, ml: 2, mr: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          Hey {user_name} !
        </Typography>
        <Typography variant="h5" sx={{ fontSize: { xs: '20px', sm: '24px', md: '30px' }, color: '#ffffff', fontFamily: 'Luckiest Guy, serif', mb: 10, mt: 1, ml: 2, mr: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          help our ai know you better
        </Typography>
        <Typography variant="h4" sx={{  fontSize: { xs: '23px', sm: '26px', md: 'inherit' }, color: '#ffffff', fontFamily: 'Quicksand, serif', mb: 2, ml:4, mr:4,  display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          What describes you best?
        </Typography>
        <Box sx={{ flexGrow: 1, mb:7 , ml:2, mr:2,}}>
          <Grid container justifyContent="center" alignItems="center" sx={{ gap:1 }}>
            <Button
              variant={role === 'marketer' ? "contained" : "outlined"}
              onClick={() => handleRoleClick('marketer')}
              sx={{  fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' }, color: role === 'marketer' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif',  mb: 1, alignItems: 'center', backgroundColor: role === 'marketer' ? '#22aba6' : '#ffffff', borderRadius: '25px' }}>
              Marketer
            </Button>
            <Button
              variant={role === 'E - commerce owner' ? "contained" : "outlined"}
              onClick={() => handleRoleClick('E - commerce owner')}
              sx={{ fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' }, color: role === 'E - commerce owner' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif',  mb: 1, alignItems: 'center', backgroundColor: role === 'E - commerce owner' ? '#22aba6' : '#ffffff', borderRadius: '25px' }}>
              E - commerce owner
            </Button>
            <Button
              variant={role === 'Advertiser' ? "contained" : "outlined"}
              onClick={() => handleRoleClick('Advertiser')}
              sx={{ fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' },  color: role === 'Advertiser' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif',  mb: 1, alignItems: 'center', backgroundColor: role === 'Advertiser' ? '#22aba6' : '#ffffff', borderRadius: '25px' }}>
              Advertiser
            </Button>
            <Button
              variant={role === 'E - commerce builder' ? "contained" : "outlined"}
              onClick={() => handleRoleClick('E - commerce builder')}
              sx={{ fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' }, color: role === 'E - commerce builder' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif',  mb: 1, alignItems: 'center', backgroundColor: role === 'E - commerce builder' ? '#22aba6' : '#ffffff', borderRadius: '25px' }}>
              E - commerce builder
            </Button>
            <Button
              variant={role === 'Social Media Manager' ? "contained" : "outlined"}
              onClick={() => handleRoleClick('Social Media Manager')}
              sx={{  fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' },   color: role === 'Social Media Manager' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif', mb: 1, alignItems: 'center', backgroundColor: role === 'Social Media Manager' ? '#22aba6' : '#ffffff', borderRadius: '25px'  }}>
              Social Media Manager
            </Button>
            <Button
              variant={role === 'Content Creator' ? "contained" : "outlined"}
              onClick={() => handleRoleClick('Content Creator')}
              sx={{ fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' },  color: role === 'Content Creator' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif', mb: 1, alignItems: 'center', backgroundColor: role === 'Content Creator' ? '#22aba6' : '#ffffff', borderRadius: '25px' }}>
              Content Creator
            </Button>
            <Button
              variant={role === 'Executive' ? "contained" : "outlined"}
              onClick={() => handleRoleClick('Executive')}
              sx={{  fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' }, color: role === 'Executive' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif', mb: 1, alignItems: 'center', backgroundColor: role === 'Executive' ? '#22aba6' : '#ffffff', borderRadius: '25px' }}>
              Executive
            </Button>
            <Button
              variant={role === 'other' ? "contained" : "outlined"}
              onClick={() => handleRoleClick('other')}
              sx={{  fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' },  color: role === 'other' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif', mb: 1, alignItems: 'center', backgroundColor: role === 'other' ? '#22aba6' : '#ffffff', borderRadius: '25px' }}>
              other
            </Button>
          </Grid>
        </Box>
        <Typography variant="h4" sx={{ fontSize: { xs: '23px', sm: '26px', md: 'inherit' }, color: '#ffffff', fontFamily: 'Quicksand, serif', mb: 2, ml:10, mr:10,  display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          Do you want to create for multiple brands or a single one?
        </Typography>
        <Box sx={{ flexGrow: 1, mb:7 }}>
          <Grid container justifyContent="center" alignItems="center"  sx={{ gap:1 }}>
            <Button
              variant={brandSelection === 'multiple' ? "contained" : "outlined"}
              onClick={() => handleBrandSelection('multiple')}
              sx={{ fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' }, color: brandSelection === 'multiple' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif', mb: 1, alignItems: 'center', backgroundColor: brandSelection === 'multiple' ? '#22aba6' : '#ffffff', borderRadius: '25px' }}>
              Multiple Brands
            </Button>
            <Button
              variant={brandSelection === 'single' ? "contained" : "outlined"}
              onClick={() => handleBrandSelection('single')}
              sx={{ fontSize: { xs: '80%%', sm: '16px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' }, color: brandSelection === 'single' ? '#ffffff' : 'black', fontFamily: 'Quicksand, serif', mb: 1, alignItems: 'center', backgroundColor: brandSelection === 'single' ? '#22aba6' : '#ffffff', borderRadius: '25px' }}>
              Single Brand
            </Button>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex',  flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Tooltip title="The link will help our AI create custom made campaigns for you">
            <HelpOutlineIcon style={{ fontSize: { xs: '26px', sm: '26px', md: 'inherit' }, width: { xs: '80%', sm: 'auto', md: 'auto' }, height: { xs: '50px', sm: '60px', md: '50px' }, color: '#ffffff', fontSize: 30,  marginBottom: 5 }} />
          </Tooltip>
          <Typography variant="h4" sx={{ fontSize: { xs: '23px', sm: '26px', md: 'inherit' }, color: '#ffffff', fontFamily: 'Quicksand, serif', mb: 2, ml:10, mr:10,  display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            {brandSelection === 'multiple' ? "Your first brand's website link you want to create to " : "Your brand's website link"}
          </Typography>
          <TextField
            variant="standard"
            sx={{
              backgroundColor: '#ffffff',
              borderRadius: '10px',
              width: '80%',
              height: '56px',
              '& input': {
                fontSize: '2.25rem',
                fontFamily: 'Quicksand, serif',
                textAlign: 'center'
              }
            }}
            value={websiteLink}
            onChange={(e) => setWebsiteLink(e.target.value)}
          />
        </Box>
        {logo ? (
          <Box
            sx={{
              mt: 10,
              mb: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <CheckCircleOutlineIcon sx={{ color: '#ffffff', mr: 1 }} />
              <Typography sx={{ color: '#ffffff', fontFamily: 'Quicksand, serif' }}>
                Uploaded Logo
              </Typography>
            </Box>
            <img src={logo} alt="Uploaded Logo" style={{ maxWidth: '300px', maxHeight: '200px', borderRadius: '25px' }} />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <Checkbox
                checked={removeBackground}
                onChange={() => setRemoveBackground(!removeBackground)}
                sx={{ color: '#ffffff' }}
              />
              <Typography sx={{ color: '#ffffff', fontFamily: 'Quicksand, serif' }}>
                Remove background?
              </Typography>
            </Box>
          </Box>
        ) : (
          <Button
            variant="contained"
            component="label"
            sx={{
              color: '#ffffff',
              mt: 10,
              mb: 2,
              width: { xs: '80%', sm: '80%', md: '300px' },
              height: { xs: '100px', sm: '150px', md: '200px' },
              borderRadius: '25px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="h4" sx={{ fontSize: { xs: 20, sm: 40, md: 40 }, color: '#ffffff', fontFamily: 'Luckiest Guy, serif',  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              Upload Logo
            </Typography>       
            <input
              type="file"
              hidden
              accept="image/png, image/jpeg"
              onChange={handleLogoUpload}
            />
          </Button>
        )}
        {showValidationMessage && (
          <Typography variant="body1" sx={{ color: 'yellow', mt: 2, fontFamily: 'Quicksand, serif', }}>
            Please fill in all required fields.
          </Typography>
        )}
        {showValidationMessage && (
          <Typography variant="body1" sx={{ color: 'yellow', mt: 2,  fontFamily: 'Quicksand, serif', }}>
            {validationMessage}
          </Typography>
        )}
        <Button 
          variant="contained"  
          onClick={handleFinish}
          sx={{ 
            color: '#ffffff', 
            mt: 6, 
            mb: 5,  
            width: { xs: '40%', sm: '30%', md: '200px' }, 
            height: { xs: '50px', sm: '100px', md: '100px' }, 
            borderRadius: '25px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(to bottom, #ecb910, #f36a1b)'
          }}
        >
          <Typography variant="h4" sx={{fontSize: { xs: 20, sm: 40, md: 50 }, color: '#ffffff', fontFamily: 'Luckiest Guy, serif', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            Finish!
          </Typography>
        </Button>
        {showCircularProgress && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ color: 'white', mb: 2 }}>
              AI processes your data and creates a custom-made dashboard for you. 5 seconds on average.
            </Typography>
            <CircularProgress />
          </Box>
        )}

      </Box>
    </div>
  )
}

export default Onboarding;
