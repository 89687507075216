import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import './styles.css'; // Create this file and import it

const pinkColor = "#cd2bff";
const clickedColor = "#6f029e";

const buttonStyle = {
    padding: '5px 40px',
    width: '100%',
    height: '60px',
    fontFamily: 'Quicksand, serif',
    background: pinkColor,
    '&:hover': {
        background: clickedColor,
    },
};

const clickedButtonStyle = {
    ...buttonStyle,
    background: clickedColor,
};


  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };


  const assistanceOptions = [
    'Brand Identity',
    'Sales Goals',
    'Broader Target Audience',
    'Lead Generation',
    'CRM',
    'Dont know yet',
    
  ];

  const options = [
    'Social Media',
    'Friend or a collegue',
    'Blog or Publication',
    'Social Media Manager',
    'Search Engine',
    'Else',
  ];

const Onboarding3Company = ({ assist, setAssist, hear, setHear}) => {

    return (
<div style={{ display: 'flex', height: '100vh', margin: 0, padding: 0}}>

<Box sx={{ width:  ['90%', '80%', '50%' ], margin: 'auto', textAlign: 'center', mt: 5 ,alignItems: 'center'}}>
            <Typography variant="h3" sx={{ color: '#00baba', fontFamily: 'Quicksand, serif'}}>
                    Almost Finished...
            </Typography>

                <Typography variant="h8" sx={{ mb: 10, color: '#a5adad',  fontFamily: 'Quicksand, serif'}}>
                     13 seconds on Ave
                </Typography>


                <Typography variant="h5" sx={{ mb: 3, mt: 6, color: '#00baba',  fontFamily: 'Quicksand, serif' }}>
                    What do you want GamifiedMarketing to assist you with the most?
                </Typography>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 6, md: 6}} style={{ marginBottom: 10 }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 6, md: 6 }} style={{ marginBottom: 10 }}>
                        {assistanceOptions.map((option, index) => (
                            <Grid key={index} item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={assist === option ? clickedButtonStyle : buttonStyle}
                                    onClick={() => setAssist(option)}
                                >
                                    {option}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <TextField
                        required
                        id="outlined-required"
                        placeholder="Other"
                        sx={{ mt: 1, width: "50%" }}
                        InputProps={{
                            style: {
                                fontFamily: 'Quicksand, serif',
                                color: pinkColor

                            },
                        }}
                    />

                <Typography variant="h5" sx={{ mb: 3, mt: 6, color: '#00baba',  fontFamily: 'Quicksand, serif' }}>
                    How did you hear about us?
                </Typography>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 6, md: 6}}>
                {options.map((option, index) => (
                    <Grid key={index} item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={hear === option ? clickedButtonStyle : buttonStyle}
                        onClick={() => setHear(option)}
                    >
                        {option}
                    </Button>
                    </Grid>
                ))}
                </Grid>

                <Typography variant="h5" sx={{ color: '#219c9a', fontFamily: 'Quicksand, serif', mt: 10, mb:20 }}>
                    Don't worry, you will always be able to update your needs later on

            </Typography>

            <Box>
                -
            </Box>

            </Box>

        </div>
    );
}

export default Onboarding3Company;
