// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAlftsV4bmhPIvC26JBDqYgVZj3O5gZ9Oc",
  authDomain: "gamifiedmarketing.firebaseapp.com",
  projectId: "gamifiedmarketing",
  storageBucket: "gamifiedmarketing.appspot.com",
  messagingSenderId: "255219187372",
  appId: "1:255219187372:web:a0a506960ba6fe088ea909"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);