import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';  // Importing the MenuIcon

const textStyles = {
  fontSize: '1.4em',
  color: "#4f4a4a",
  fontFamily: 'Quicksand, serif',
};

const scrollableContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  overflowY: 'hidden',
  backgroundColor: '#edfaf9',
  marginTop: -2
};

const SecondUpBar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderNavigation = () => {
    if (isSmallScreen) {
      return (
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{ ...textStyles, ml: 1, mr: 7 }}
          >
            <MenuIcon />
          </Button>

        <Button variant="text" onClick={() => navigate("/CompanyHome")} sx={{ ...textStyles, mr: 5 }}>
            New campaign
          </Button>


          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            PaperProps={{ style: { width: '320px' } }}
          >
            <MenuItem onClick={() => navigate("/CompanyHome")}>Home</MenuItem>
            <MenuItem onClick={() => navigate("/Campaigns")}>Campaigns</MenuItem>
            <MenuItem onClick={() => navigate("/DesignProfile")}>Design Profile</MenuItem>
            {/* Additional menu items */}

            
          </Menu>
        </div>
      );
    } else {
      return (
        <>

        <Button variant="text" onClick={() => navigate("/CompanyHome")} sx={{ ...textStyles, mr: 5 }}>
            New campaign
          </Button>

          <Button variant="text" onClick={() => navigate("/CompanyHome")} sx={textStyles}>
            Home
          </Button>
          <Button variant="text" onClick={() => navigate("/Campaigns")} sx={textStyles}>
            Campaigns
          </Button>
          <Button variant="text" onClick={() => navigate("/DesignProfile")} sx={textStyles}>
            Design Profile
          </Button>
        </>
      );
    }
  };

  return (
    <div>
      <Box sx={scrollableContainerStyles}>
        {renderNavigation()}
      </Box>
      <Divider />
    </div>
  );
};

export default SecondUpBar;
