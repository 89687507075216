import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import BrushIcon from '@mui/icons-material/Brush';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

import UpBar from './UpBar'

import './styles.css'; // Create this file and import it
import CompanyCard from './CompanyCard';
import { AppBar, Card } from '@mui/material';

const pinkColor = "#cd2bff";


const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'

  };

  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };




const LeftNavBar = () => {

    return (

<Stack direction={"column"} p={2} sx={{ position: 'sticky', background: '#c9edff', width: '150px', height:'100vh', alignItems: 'flex-start' }}>
  <Button variant="text" color="primary" sx={{ mb: 5, mt:"100px", color: pinkColor ,  fontFamily: 'Quicksand, serif',         border: '2px solid',     fontSize: '1rem',     borderRadius: '20px', // Adjust the border radius as needed
 // Adjust the font size as needed
// Add border property here
 }}>
    New Campaign
  </Button>
  <Button variant="text" color="primary" sx={{ mb: 1, color: pinkColor,  fontFamily: 'Quicksand, serif',  fontSize: '1rem' }}>
  <ArrowCircleUpIcon sx={{ marginRight: '0.5rem' }} /> {/* Adjust the margin as needed */}

    Campaigns
    
  </Button>
  <Button variant="text" color="primary" sx={{ mb: 1 , color: pinkColor ,  fontFamily: 'Quicksand, serif',  fontSize: '1rem'}}>
  <BrushIcon sx={{ marginRight: '0.5rem' }} /> {/* Adjust the margin as needed */}

    Profile
  </Button>
  <Button variant="text" color="primary" sx={{ mb: 1 , color: pinkColor ,  fontFamily: 'Quicksand, serif',  fontSize: '1rem' }}>
  <SettingsIcon sx={{ marginRight: '0.5rem' }} /> {/* Adjust the margin as needed */}

    Settings
  </Button>

</Stack>


    );
}

export default LeftNavBar;
