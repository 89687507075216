import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import { useState, useEffect } from 'react';
import RightSideLogIn from './RightSideLogIn'
import lottie from "lottie-web";
// import groovyWalkAnimation from "https://GamifiedMarketing-PullZone.b-cdn.net/Lotties/clouds/ShapeCloud%20BG%20Large%20(1)/1st%20Cloud%20BG%2002.json";

const Login = () => {

    useEffect(() => {
        let PresentANIMww = lottie.loadAnimation({
          container: document.getElementById("animationGift-container1"),
          path: `https://GamifiedMarketing-PullZone.b-cdn.net/Lotties/coins/05.json`,
          autoplay: true,
          loop: true,
          renderer: 'svg'
        });
        // Optionally return a cleanup function if needed
        return () => {
            PresentANIMww.destroy(); // Destroy animation when component unmounts
        };
      }, []); // Empty dependency array to ensure this effect runs only once

    return (
        <div style={{ display: 'flex', height: '100vh', margin: 0, padding: 0 , backgroundImage: 'url("https://GamifiedMarketing-PullZone.b-cdn.net/9307298.png")', backgroundPosition: 'center',           backgroundSize: 'cover',
        // Set the background image here
    }}>
            <Stack direction="row" spacing={2} flex={1}>
                <Box
                    sx={{
                        backgroundImage: 'radial-gradient(circle, #89e3f5, #49d6f2, #0084b8)',
                        color: 'white',
                        width: { xs: '100%', sm: '90%' },
                        display: { xs: 'none', md: 'block' },
                         p: 0,
                         borderRadius: '2%',
                         overflow: 'hidden', // Added overflow property
                         position: 'relative', // Added position property
                         

                    }}
                    
                >
            <div id="animationGift-container1" style={{ position: 'absolute', top: '-20%', left: '-20%', width: '150%', height: '150%', zIndex: 99 }}></div>

                </Box>
                <Box
                    sx={{
                        backgroundImage: '',
                        color: 'white',
                        padding: '10px',
                        width: { xs: '100%', sm: '100%' },
                        m: 0,  
                    }}
                >
                            {/* <div id="animationGift-container" style={{ position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%', zIndex: 99 }}></div> */}

                    <RightSideLogIn></RightSideLogIn>
                </Box>
            </Stack>
        </div>
    );
}

export default Login;
