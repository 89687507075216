import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

import './styles.css'; // Create this file and import it

const pinkColor = "#cd2bff";


const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'

  };

  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };


const UpBarAgency = () => {

    return (

        <Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>



        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

        <Typography sx={{ fontFamily: 'Quicksand, serif', position: "absolute", left:"20px", fontWeight: 'bold' }}>
            <span style={{ color: pinkColor }}>Gamified</span>
            <span style={{ color: '#00bdde' }}>Marketing</span>.ai
        </Typography>


        <Avatar sx={{mr:1}}>all</Avatar>
        <Avatar sx={{mr:1}}>H</Avatar>
        <Avatar sx={{mr:1}}>H</Avatar>
        <Avatar sx={{mr:1}}>H</Avatar>
        <Avatar sx={{mr:1}}>H</Avatar>
        <Avatar sx={{mr:1}}>H</Avatar>


                <InputBase
                sx={{ ml: 25, flex: 1 }}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search google maps' }}
            />

            <IconButton type="button" sx={{ p: '10px', mr:10}} aria-label="search">
                <SearchIcon />
            </IconButton>
            

            <NotificationsOutlinedIcon sx={{ mr:4 }} />
            <HelpOutlineOutlinedIcon sx={{ mr:4 }} />
            <Avatar>H</Avatar>


        </Box>


        </Box>

        <Box>

        <Divider style={{ marginTop: '20px' }} />

        </Box>

    </Box>



    );
}

export default UpBarAgency;
