import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  { useState } from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import './styles.css'; // Create this file and import it

import { useUserContext } from "../App";


const UserFirstName = "John";

const pinkColor = "#cd2bff";
const clickedColor = "#6f029e";


const buttonStyle = {
    padding: '5px 40px',
    width: '100%',
    height: '60px',
    fontFamily: 'Quicksand, serif',
    background: pinkColor,
    '&:hover': {
        background: clickedColor,
    },
};

const clickedButtonStyle = {
    ...buttonStyle,
    background: clickedColor,
};


  const Onboarding1 = ({ partOf, setPartOf, occupation, setOccupation, pets, setPets, websiteLink, setWebsiteLink }) => {

    // const [partOf, setPartOf] = useState(null);
    // const [occupation, setOccupation] = useState(null);
    // const [pets, setPets] = useState(null);
    
    const UserContext = useUserContext();

    console.log("HERE---------")

    const Name = UserContext.userDetails.user.displayName;
    console.log(UserContext.userDetails.user.displayName)


    return (
<div style={{ display: 'flex', height: '100vh', margin: 0, padding: 0}}>

<Box sx={{ width:  ['90%', '80%', '50%' ], margin: 'auto', textAlign: 'center', mt: 5 ,alignItems: 'center'}}>
            <Typography variant="h3" sx={{ color: '#00baba', fontFamily: 'Quicksand, serif'}}>
                    Hi {Name}, let's get to know you!
            </Typography>

                <Typography variant="h8" sx={{ mb: 10, color: '#a5adad',  fontFamily: 'Quicksand, serif'}}>
                     11 seconds on Ave
                </Typography>

                <Typography variant="h5" sx={{ mb: 3, mt: 6, color: '#00baba',  fontFamily: 'Quicksand, serif' }}>
                    What are you part of?
                </Typography>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 6, md: 6}} style={{ marginBottom: 10 }}>
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary"                
                                sx={partOf === 'Agency' ? clickedButtonStyle : buttonStyle}
                                onClick={() => setPartOf('Agency')}>
                            Agency
                        </Button>

                    </Grid>
                    
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary"                
                                sx={partOf === 'Single entity' ? clickedButtonStyle : buttonStyle}
                                onClick={() => setPartOf('Single entity')}>
                            Single entity
                        </Button>
                    </Grid>

                </Grid>

            <TextField 
                id="standard-basic" 
                label="Website Link" 
                variant="standard" 
                value={websiteLink} 
                onChange={(e) => setWebsiteLink(e.target.value)} 
                required 
                sx={{ mt: 5 }} 
            />

                
                <Typography variant="h5" sx={{ mb: 3, mt: 6, color: '#00baba',  fontFamily: 'Quicksand, serif' }}>
                    What describes you best?
                </Typography>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 6, md: 6}}>
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary"                
                                sx={occupation === 'Marketing Manager' ? clickedButtonStyle : buttonStyle}
                                onClick={() => setOccupation('Marketing Manager')}>
                            Marketing Manager
                        </Button>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary"                
                                    sx={occupation === 'Digital Marketer' ? clickedButtonStyle : buttonStyle}
                                    onClick={() => setOccupation('Digital Marketer')}>
                                Digital Marketer
                        </Button>
                                            
                    </Grid>

                    <Grid item xs={6}>
                        <Button variant="contained" color="primary"                
                                    sx={occupation === 'Content Creator' ? clickedButtonStyle : buttonStyle}
                                    onClick={() => setOccupation('Content Creator')}>
                                Content Creator
                        </Button>                    
                    </Grid>

                    <Grid item xs={6}>
                        <Button variant="contained" color="primary"                
                                    sx={occupation === 'Social Media Manager' ? clickedButtonStyle : buttonStyle}
                                    onClick={() => setOccupation('Social Media Manager')}>
                                Social Media Manager
                        </Button>     
                    </Grid>



                    <Grid item xs={6}>
                        <Button variant="contained" color="primary"                
                                    sx={occupation === 'Executive and decision-maker' ? clickedButtonStyle : buttonStyle}
                                    onClick={() => setOccupation('Executive and decision-maker')}>
                                Executive and decision-maker
                        </Button>     
                    </Grid>


                    <Grid item xs={6}>
                        <Button variant="contained" color="primary"                
                                    sx={occupation === 'Else' ? clickedButtonStyle : buttonStyle}
                                    onClick={() => setOccupation('Else')}>
                                Else
                        </Button>     
                    </Grid>

                </Grid>



                
                <Typography variant="h5" sx={{ mb: 3, mt: 6, color: '#00baba',  fontFamily: 'Quicksand, serif' }}>
                    Cats or Dogs?
                </Typography>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 6, md: 6 }} sx={{ mb: 7, }}>
                <Grid item xs={6}>
                    <Button variant="contained" color="primary"
                        sx={pets === 'Cats' ? clickedButtonStyle : buttonStyle}
                        onClick={() => setPets('Cats')}>
                        Cats
                    </Button>
                </Grid>

                <Grid item xs={6}>
                    <Button variant="contained" color="primary"
                        sx={pets === 'Dogs' ? clickedButtonStyle : buttonStyle}
                        onClick={() => setPets('Dogs')}>
                        Dogs
                    </Button>
                </Grid>
            </Grid>

            </Box>


        </div>
    );
}

export default Onboarding1;
