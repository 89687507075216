// import React, { useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import Link from '@mui/material/Link';
// import Divider from '@mui/material/Divider';
// import GoogleIcon from '@mui/icons-material/Google';
// import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup , sendPasswordResetEmail} from 'firebase/auth';
// import { auth } from '../firebase-config.js';
// import { useUserContext } from '../App';
// import './styles.css';

// const provider = new GoogleAuthProvider();

//   const LoginCard = ({ onSignUpClick }) => {
//   const { setUserDetails } = useUserContext();
//   const navigate = useNavigate();


//   const [emailForgotPassword, setemailForgotPassword] = useState("");
//   const handleEmailForgotPasswordChange = (event) => {
//     setemailForgotPassword(event.target.value);
//   };

//   const [emailSent, setEmailSent] = useState(false);


//   const [loginEmail, setLoginEmail] = useState("");
//   const [loginPassword, setLoginPassword] = useState("");
//   const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);

//   const handleEmailChange = (event) => {
//     setLoginEmail(event.target.value);
//   };

//   const handlePasswordChange = (event) => {
//     setLoginPassword(event.target.value);
//   };

//   const loginGoogle = async () => {
//     try {
//       const user = await signInWithPopup(auth, provider);
//       setUserDetails(user);
//       navigate('/CompanyHome');
//     } catch (error) {
//       console.log(error.message);
//     }
//   };


//   const sendPasswordResetEmailfunction = async () => {
//     try {
//       await sendPasswordResetEmail(auth, emailForgotPassword);
//       console.log("email sent!");
//       setEmailSent(true);
//     } catch (error) {
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       // Handle error if needed
//     }
//   };



//   const login = async () => {
//     try {
//       const user = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
//       setUserDetails(user);
//       navigate('/CompanyHome');
//     } catch (error) {
//       console.log(error.message);
//     }
//   };

//   const handleForgotPasswordClick = () => {
//     setForgotPasswordClicked(true);
//     setEmailSent(false); // Reset email sent status when switching to forgot password mode
//   };

//   const handleBackToLoginClick = () => {
//     setForgotPasswordClicked(false);
//   };

//   const pinkColor = "#ee01fd";

//   return (
//     <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '80vw', left: '-7px'}}>
//       <Box sx={{ textAlign: 'center', p: 3, width: 300, boxShadow: '0 0 3px rgba(0, 0, 0, 1)', borderRadius: 10, color: 'radial-gradient(circle, #89e3f5, #49d6f2, #fffff)' }}>
//         <Typography variant="h4" component="div" sx={{ width: "100%", mb: 4, color: pinkColor , fontFamily: 'Luckiest Guy, serif' }}>
//           {forgotPasswordClicked ? 'Forgot your Password?' : 'Login'}
//         </Typography>

//         {forgotPasswordClicked ? (
//           <div style={{ padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
//             {/* Implement your forgot password UI here */}
//             <Typography variant="body1" sx={{ color: 'black', mb : 2 }}>
//               Enter you email below to reset your password
//             </Typography>

//             <TextField
//               id="outlined-basic"
//               label="Email"
//               variant="outlined"
//               sx={{ width: "100%", mb: 1, fontFamily: 'Quicksand, serif' }}
//               onChange={handleEmailForgotPasswordChange}
//             />
//         <Button
//           variant="contained"
//           sx={{
//             width: "80%",
//             fontSize: "0.8rem",
//             fontFamily: 'Quicksand, serif',
//             marginTop: 1 // Adding margin-top of 4
//           }}
          
//           onClick={sendPasswordResetEmailfunction}
//         >
//           submit
//         </Button>

//         {emailSent && (
//               <Typography variant="body1" sx={{ color: 'black', mb : 2 }}>
//                 Email sent
//               </Typography>
//             )}


//             <Button variant="contained" sx={{
//             width: "50%",
//             fontSize: "0.8rem",
//             backgroundColor: pinkColor,
//             fontFamily: 'Quicksand, serif',
//             marginTop: 6 // Adding margin-top of 4
//           }}  onClick={handleBackToLoginClick}>Back to Login</Button>
//           </div>
//         ) : (
//           <>
//             <TextField
//               id="outlined-basic"
//               label="Email"
//               variant="outlined"
//               sx={{ width: "100%", mb: 1, fontFamily: 'Quicksand, serif' }}
//               onChange={handleEmailChange}
//             />
//             <TextField
//               id="outlined-password-input"
//               label="Password"
//               type="password"
//               autoComplete="current-password"
//               sx={{ width: "100%", mb: 3, fontFamily: 'Quicksand, serif' }}
//               onChange={handlePasswordChange}
//             />
//             <Button variant="contained" sx={{ width: "100%", fontSize: "0.8rem", backgroundColor: pinkColor, fontFamily: 'Quicksand, serif' }} onClick={login}>Log In</Button>
//             <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
//               <Typography variant="body2" sx={{ marginTop: 2 }}>
//                 <Link href="#" sx={{ color: 'black', fontFamily: 'Quicksand, serif' }} onClick={handleForgotPasswordClick}>Forgot password?</Link>
//               </Typography>
//             </Box>
//           </>
//         )}

//         <Divider style={{ color: 'black' }}>or</Divider>

//         <Button
//           variant="contained"
//           sx={{
//             width: "80%",
//             fontSize: "0.8rem",
//             backgroundColor: pinkColor,
//             fontFamily: 'Quicksand, serif',
//             marginTop: 4 // Adding margin-top of 4
//           }}
//           startIcon={<GoogleIcon />}
//           onClick={loginGoogle}
//         >
//           Log in with Google
//         </Button>

//         {!forgotPasswordClicked && (
//           <Typography variant="body2" sx={{ marginTop: 5, color: 'black', fontFamily: 'Quicksand, serif' }}>
//             Don't have an account?
//             <Link
//               href="#"
//               sx={{ color: pinkColor, fontFamily: 'Quicksand, serif', marginLeft: '10px' }}
//               onClick={onSignUpClick}
//             >
//               Sign Up!
//             </Link>
//           </Typography>
//         )}

//       </Box>
//     </div>
//   );
// }

// export default LoginCard;


import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import GoogleIcon from '@mui/icons-material/Google';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase-config.js';
import { useUserContext } from '../App';
import './styles.css';

const provider = new GoogleAuthProvider();

const LoginCard = ({ onSignUpClick }) => {
  const { setUserDetails } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [emailForgotPassword, setemailForgotPassword] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [editParams, setEditParams] = useState({});

  useEffect(() => {
    // Extract parameters from the URL fragment (after the `#`)
    const hashParams = location.hash.split('?')[1];
    const searchParams = new URLSearchParams(hashParams);

    const edit = searchParams.get('edit');
    const b = searchParams.get('b');
    const c = searchParams.get('c');

    if (edit === 'yes' && b && c) {
      setEditParams({ b, c });
      setRedirectToEdit(true);
    }

    // Check if the user is already logged in
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserDetails(user);
        // If the user is authenticated and should be redirected to the edit page
        if (redirectToEdit && editParams.b && editParams.c) {
          navigate(`/NewCampaignEcommerce?b=${editParams.b}&c=${editParams.c}`);
        } else {
          navigate('/CompanyHome');
        }
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [location, redirectToEdit, editParams, navigate, setUserDetails]);

  const handleEmailChange = (event) => {
    setLoginEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setLoginPassword(event.target.value);
  };

  const loginGoogle = async () => {
    try {
      const user = await signInWithPopup(auth, provider);
      setUserDetails(user);
      if (redirectToEdit && editParams.b && editParams.c) {
        navigate(`/NewCampaignEcommerce?b=${editParams.b}&c=${editParams.c}`);
      } else {
        navigate('/CompanyHome');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const sendPasswordResetEmailfunction = async () => {
    try {
      await sendPasswordResetEmail(auth, emailForgotPassword);
      setEmailSent(true);
    } catch (error) {
      console.log(error.message);
    }
  };

  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      setUserDetails(user);
      if (redirectToEdit && editParams.b && editParams.c) {
        navigate(`/NewCampaignEcommerce?b=${editParams.b}&c=${editParams.c}`);
      } else {
        navigate('/CompanyHome');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleForgotPasswordClick = () => {
    setForgotPasswordClicked(true);
    setEmailSent(false); // Reset email sent status when switching to forgot password mode
  };

  const handleBackToLoginClick = () => {
    setForgotPasswordClicked(false);
  };

  const pinkColor = "#ee01fd";

  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '80vw', left: '-7px' }}>
      <Box sx={{ textAlign: 'center', p: 3, width: 300, boxShadow: '0 0 3px rgba(0, 0, 0, 1)', borderRadius: 10 }}>
        <Typography variant="h4" component="div" sx={{ width: "100%", mb: 4, color: pinkColor, fontFamily: 'Luckiest Guy, serif' }}>
          {forgotPasswordClicked ? 'Forgot your Password?' : 'Login'}
        </Typography>

        {forgotPasswordClicked ? (
          <div style={{ padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
            <Typography variant="body1" sx={{ color: 'black', mb: 2 }}>
              Enter your email below to reset your password
            </Typography>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              sx={{ width: "100%", mb: 1, fontFamily: 'Quicksand, serif' }}
              onChange={(e) => setemailForgotPassword(e.target.value)}
            />
            <Button
              variant="contained"
              sx={{ width: "80%", fontSize: "0.8rem", fontFamily: 'Quicksand, serif', marginTop: 1 }}
              onClick={sendPasswordResetEmailfunction}
            >
              Submit
            </Button>
            {emailSent && (
              <Typography variant="body1" sx={{ color: 'black', mb: 2 }}>
                Email sent
              </Typography>
            )}
            <Button variant="contained" sx={{ width: "50%", fontSize: "0.8rem", backgroundColor: pinkColor, fontFamily: 'Quicksand, serif', marginTop: 6 }} onClick={handleBackToLoginClick}>
              Back to Login
            </Button>
          </div>
        ) : (
          <>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              sx={{ width: "100%", mb: 1, fontFamily: 'Quicksand, serif' }}
              onChange={handleEmailChange}
            />
            <TextField
              id="outlined-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
              sx={{ width: "100%", mb: 3, fontFamily: 'Quicksand, serif' }}
              onChange={handlePasswordChange}
            />
            <Button variant="contained" sx={{ width: "100%", fontSize: "0.8rem", backgroundColor: pinkColor, fontFamily: 'Quicksand, serif' }} onClick={login}>
              Log In
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                <Link href="#" sx={{ color: 'black', fontFamily: 'Quicksand, serif' }} onClick={handleForgotPasswordClick}>
                  Forgot password?
                </Link>
              </Typography>
            </Box>
          </>
        )}

        <Divider style={{ color: 'black' }}>or</Divider>

        <Button
          variant="contained"
          sx={{ width: "80%", fontSize: "0.8rem", backgroundColor: pinkColor, fontFamily: 'Quicksand, serif', marginTop: 4 }}
          startIcon={<GoogleIcon />}
          onClick={loginGoogle}
        >
          Log in with Google
        </Button>

        {!forgotPasswordClicked && (
          <Typography variant="body2" sx={{ marginTop: 5, color: 'black', fontFamily: 'Quicksand, serif' }}>
            Don't have an account?
            <Link
              href="#"
              sx={{ color: pinkColor, fontFamily: 'Quicksand, serif', marginLeft: '10px' }}
              onClick={onSignUpClick}
            >
              Sign Up!
            </Link>
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default LoginCard;
