import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import Badge from '@mui/material/Badge';

import UpBar from './UpBar';
import SecondUpBar from './SecondUpBar'
import LeftNavBar from './LeftNavBar'
import CampaignStatisticsCard from './CampaignStatisticsCard'

import './styles.css'; // Create this file and import it
import CompanyCard from './CompanyCard';
import { AppBar, Card } from '@mui/material';
import { pink } from '@mui/material/colors';




import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useBrand } from '../contexts/BrandContext'; // Correct import path
import {useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { getDatabase, ref, onChildAdded, off, onChildChanged, onValue , set} from 'firebase/database';

const pinkColor = "#cd2bff";


const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'

  };

  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };








const Campaigns = () => {

    const { currentBrand } = useBrand (); // Access the currentAccount from AccountContext

    const [searchParams] = useSearchParams();
    const campaignID = searchParams.get('campaignID');  // Retrieve 'campaignID' from URL query parameters
    const navigate = useNavigate();


    const [UID, setUID] = React.useState(``);
    const [user, setUser] = React.useState(null); // State to hold the user object
    const [urls, setUrls] = React.useState([]);

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
  
        useEffect(() => {

            console.log("currentBrand ", currentBrand )
            const auth = getAuth();
            const db = getDatabase();
    
            const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
                if (user) {

                    setUser(user);

                    // Define the path to the URLs based on the authenticated user and current account
                    const gameGenerationRef = ref(db, `users/${user.uid}/brands/${currentBrand ?.id}/NewCampaignManual/Ecommerce/`);
                    const ecommerceRef = ref(db, `users/${user.uid}/brands/${currentBrand ?.id}/NewCampaignManual/Ecommerce/`);

                    console.log("gameGenerationRef", gameGenerationRef)
                    onValue(ecommerceRef, (snapshot) => {
                        const campaignData = snapshot.val();
                        console.log("inside onValue", campaignData)

                        const collectedUrls = []; // Initialize an array to collect URLs
        
                        if (campaignData) {

                            console.log("insidecampaignData  currentBrand ->>>", currentBrand )
                            // Iterate over each campaign by ID
                            Object.keys(campaignData).forEach(campaignID => {
                                const campaignDetails = campaignData[campaignID];
                                // Check if the campaign details contain a 'url' key
                                if (campaignDetails && campaignDetails.url) {
                                    collectedUrls.push(campaignDetails.url); // Collect each URL
                                }
                            });
        
                            setUrls(collectedUrls); // Update state with all collected URLs
                        }else{
                            setUrls([]); // Set URLs to an empty array if campaignData is null or empty

                        }
                    }, {
                    
                        onlyOnce: true
                    });
                } else {
                    navigate('/login');
                }
            });
    
            return () => {
                unsubscribeAuth();
            };
        }, [navigate, campaignID, currentBrand ]);
    


        const campaignCards = urls.length > 0 ? urls.map((urlString, index) => (
            <Grid item key={index} xs={12} sm={12} lg={6}>
                <CampaignStatisticsCard urlString={urlString} />
            </Grid>
        )) : (
            <Typography sx={{ textAlign: 'center', width: '100%' }}>No campaigns yet.</Typography>
        );
    

    return (

        <Box sx={{
            width: "100%",
            overflow: 'hidden',
            backgroundColor: '#f5f9ff',
            backgroundImage: 'url("https://GamifiedMarketing-PullZone.b-cdn.net/9307298.png")', // Set the background image here
            // backgroundSize: 'cover', // Ensure the background image covers the entire Box
            backgroundPosition: 'center' // Center the background image
          }}>


            <Box sx={{ width: '100%', position: 'fixed' }}>
                <UpBar />
            </Box>

 

                    <Typography variant="h3" sx={{ color: '#d90d7d', fontFamily: 'Luckiest Guy, serif', mb: 10, mt:20,  ml:2, mr:2, display: 'flex', flexDirection: 'column', alignItems: 'center',   textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)' }}>
                        Your campaigns
                    </Typography>





                    <Grid container spacing={2} sx={{ marginBottom: 2, mr:2 , display: "flex", justifyContent: "center",   alignItems: "center",  ml: "auto",  mr: "auto", }}>
                        {campaignCards} 
                    </Grid>

        </Box>
    );
}

export default Campaigns;
