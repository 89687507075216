import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './styles.css'; // Create this file and import it
import OnboardingUpload from './OnboardingUpload';

const pinkColor = "#cd2bff";


const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'

  };

  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };


const Onboarding2Company = () => {

    return (
<div style={{ display: 'flex', height: '100vh', margin: 0, padding: 0}}>


    <Box sx={{ width:  ['90%', '80%', '50%' ], margin: 'auto', textAlign: 'center', mt: 5, mb:5,alignItems: 'center'}}>


            <Typography variant="h3" sx={{ color: '#00baba', fontFamily: 'Quicksand, serif'}}>
                    Now we want to understand your comany's design
            </Typography>  

            <Typography variant="h6" sx={{ mb: 1, mt: 2, color: pinkColor,  fontFamily: 'Quicksand, serif'}}>
            It will help our AI create a beautiful, custom-made design for you. 
                </Typography> 

            <Typography variant="h8" sx={{ mb: 4, color: '#a5adad',  fontFamily: 'Quicksand, serif'}}>
                     2 minutes on Ave
            </Typography>

            <div style={{ marginTop: '50px' }}>
                    <OnboardingUpload Text="Logo" />
                    <OnboardingUpload Text="Art" />
                </div>

            <Typography variant="h5" sx={{ color: '#219c9a', fontFamily: 'Quicksand, serif', mt: 10, mb:20 }}>
                    Don't worry, you will always be able to update your Design profile later on

            </Typography>

            <Box>
                -
            </Box>




    </Box>


        </div>
    );
}

export default Onboarding2Company;
