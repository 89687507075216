import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get, set } from "firebase/database";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';

import { Visibility, VisibilityOff, FileCopy } from '@mui/icons-material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import UpBar from './UpBar';
import './styles.css';

import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51OGMPyJyeVT30U3Jj1wDAAFxzMjUFl7YOzokmtQbRI8fhF3lkDtmV0YkVBd4V7XDlLm6xpYa0ic1fmYC7XUxN7aZ00cLcZ3EBm');


const Account = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [showApiKey, setShowApiKey] = useState(false);
  const [hasApiKey, setHasApiKey] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [numberOfBrands, setNumberOfBrands] = useState(0);
  const [numberOfActiveCampaigns, setNumberOfActiveCampaigns] = useState(0);
  const [numberOfMonthlyGameSessions, setNumberOfMonthlyGameSessions] = useState(0);
  const [showUpgrade, setShowUpgrade] = useState(false);

  const auth = getAuth();
  const db = getDatabase();

  const SUBSCRIPTION_LIMITS = {
    "free trial": {
      numberOfBrands: 1,
      numberOfActiveCampaigns: 3,
      numberOfMonthlyGameSessions: 2500
    },
    "prod_QYc8C8kokIpi5c": {
      numberOfBrands: 3,
      numberOfActiveCampaigns: 100,
      numberOfMonthlyGameSessions: 50000
    }
  };

  const [subscriptionLimits, setSubscriptionLimits] = useState(SUBSCRIPTION_LIMITS["free trial"]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        console.log(currentUser.email)
        checkApiKey(currentUser.uid);
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        const subscriptionTypeRef = ref(db, `users/${uid}/subscription/type`);
        const brandsRef = ref(db, `users/${uid}/subscription/numberOfBrands`);
        const campaignsRef = ref(db, `users/${uid}/subscription/numberOfActiveCampaigns`);
        const sessionsRef = ref(db, `users/${uid}/subscription/numberOfMonthlyGameSessions`);

        const [subscriptionSnapshot, brandsSnapshot, campaignsSnapshot, sessionsSnapshot] = await Promise.all([
          get(subscriptionTypeRef),
          get(brandsRef),
          get(campaignsRef),
          get(sessionsRef)
        ]);

        if (subscriptionSnapshot.exists()) {
          const subscriptionType = subscriptionSnapshot.val();
          setShowUpgrade(subscriptionType === "free trial");

          if (brandsSnapshot.exists() && campaignsSnapshot.exists() && sessionsSnapshot.exists()) {
            const limits = SUBSCRIPTION_LIMITS[subscriptionType] || {};
            setNumberOfBrands(brandsSnapshot.val());
            setNumberOfActiveCampaigns(campaignsSnapshot.val());
            setNumberOfMonthlyGameSessions(sessionsSnapshot.val());
            setSubscriptionLimits(limits);
          }
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }

      try {
        const brandsCapacityRef = ref(db, `users/${uid}/subscription/numberOfBrandsCAPACITY`);
        const campaignsCapacityRef = ref(db, `users/${uid}/subscription/numberOfActiveCampaignsCAPACITY`);
        const sessionsCapacityRef = ref(db, `users/${uid}/subscription/numberOfMonthlyGameSessionsCAPACITY`);

        const [brandsCapacitySnapshot, campaignsCapacitySnapshot, sessionsCapacitySnapshot] = await Promise.all([
          get(brandsCapacityRef),
          get(campaignsCapacityRef),
          get(sessionsCapacityRef)
        ]);

        if (brandsCapacitySnapshot.exists() && campaignsCapacitySnapshot.exists() && sessionsCapacitySnapshot.exists()) {
          setSubscriptionLimits({
            numberOfBrands: brandsCapacitySnapshot.val(),
            numberOfActiveCampaigns: campaignsCapacitySnapshot.val(),
            numberOfMonthlyGameSessions: sessionsCapacitySnapshot.val()
          });
        } else {
          console.log("No capacity data available");
        }
      } catch (error) {
        console.error("Error fetching capacity data: ", error);
      }
    };

    if (user) {
      fetchUserData(user.uid);
    }
  }, [user]);

  useEffect(() => {
    console.log("numberOfBrands:", numberOfBrands);
    console.log("subscriptionLimits:", subscriptionLimits);
  }, [numberOfBrands, subscriptionLimits]);

  const checkApiKey = async (uid) => {
    try {
      const apiKeyRef = ref(db, `users/${uid}/apiKey`);
      const snapshot = await get(apiKeyRef);
      if (snapshot.exists()) {
        setApiKey(snapshot.val());
        setHasApiKey(true);
      } else {
        setHasApiKey(false);
      }
    } catch (error) {
      console.error("Error checking API key: ", error);
      setHasApiKey(false);
    }
  };

  const generateApiKey = async () => {
    if (!user) return;

    // Firebase storage upload logic here
    console.log("Inside generateApiKey");

    const formData = new FormData();
    formData.append('userUid', user.uid);
    try {
      const response = await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: {
          'react-app': 'APIgenerator' // Custom header
        },
        body: formData
      });
      const responseData = await response.json();
      if (response.ok) {
        const newApiKey = responseData.message; // Assuming the API returns the key in `apiKey`
        console.log("responseData.message", responseData.message)
        setApiKey(newApiKey);
        setHasApiKey(true);
        // Save the new API key to the database
        const apiKeyRef = ref(db, `users/${user.uid}/apiKey`);
        await set(apiKeyRef, newApiKey);
        // Check and refresh the API key
        await checkApiKey(user.uid);
      } else {
        console.log(response.head)
        console.error('Failed to generate API key');
      }
    } catch (error) {
      console.error('Failed to generate API key', error);
      // In case of error, try to check and refresh the API key
      await checkApiKey(user.uid);
      setHasApiKey(true);
    };
  }



//hello
  // const createCheckoutSession = async (priceId) => {
  //   try {
  //     const response = await fetch('https://gamifiedmarketing.ai:3000', {
  //       method: 'POST',
  //       headers: {
  //         'react-app': 'StripeCreateCheckoutSession'
  //       },
  //       body: JSON.stringify({
  //         priceId,
  //         successUrl: window.location.origin,
  //         cancelUrl: window.location.origin,
  //       }),
  //     });
  
  //     const data = await response.json();
  //     const stripe = await stripePromise;
  //     await stripe.redirectToCheckout({ sessionId: data.message });
  //   } catch (error) {
  //     console.error('Error creating checkout session:', error);
  //   }
  // };



  const createCheckoutSession = async (priceId
  ) => {
    console.log("createCheckoutSession started! with->", priceId);
    
    try {
      const formData = new FormData();
      formData.append('priceId', priceId);
      formData.append('customerEmail', user.email);
      formData.append('successUrl', window.location.origin);
      formData.append('cancelUrl', window.location.origin);
  
      const response = await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: {
          'react-app': 'StripeCreateCheckoutSession'
        },
        body: formData,
      });
  
      const data = await response.json();
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: data.message });
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };
   


  const handleChangePlan = async () => {
    console.log("Change Plan button clicked");
    
    try {
      const formData = new FormData();
      formData.append('customerEmail', user.email);
      formData.append('successUrl', window.location.origin);
      formData.append('cancelUrl', window.location.origin);
  
      const response = await fetch('https://gamifiedmarketing.ai:3000', {
        method: 'POST',
        headers: {
          'react-app': 'StripeCreatePortalSession'
        },
        body: formData,
      });
  
      const data = await response.json();
      window.location.href = data.message;  // Redirect to the URL under data.message
    } catch (error) {
      console.error('Error creating StripeCreatePortalSession :', error);
    }
  };
  
  

  const handleUpgradePlan = () => {
    console.log("handleUpgradePlan started!")
    createCheckoutSession('price_1PhUuiJyeVT30U3JZXZaqyAo'); // Replace with your price ID
  };
  
  const upgradeBrands = () => {
    createCheckoutSession('price_1PhU0nJyeVT30U3JuBaRMUQ4'); // Replace with your price ID
  };

  const upgradeSessions = () => {
    createCheckoutSession('price_1PhU1VJyeVT30U3JQeZQZiuZ'); // Replace with your price ID
  };



  const toggleApiKeyVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  const handleClickNewCampaign = () => {
    navigate('/NewCampaignEcommerce');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
  };

  const handleChangeApiKey = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmChangeApiKey = () => {
    generateApiKey();
    setDialogOpen(false);
  };

  return (
    <Box sx={{
      width: "100%",
      overflow: 'hidden',
      backgroundColor: '#f5f9ff',
      backgroundImage: 'url("https://GamifiedMarketing-PullZone.b-cdn.net/9307298.png")',
      backgroundPosition: 'center'
    }}>
      <Box sx={{ width: '100%', position: 'fixed', zIndex: 999 }}>
        <UpBar />
      </Box>

      <Fab
        variant="extended"
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 100,
          fontFamily: 'Quicksand, serif',
          background: 'linear-gradient(45deg, #34deeb 50%, #e6128e 10%)',
          color: 'white',
          transition: 'transform 0.3s ease',
          '&:hover': {
            transform: 'scale(1.2)'
          }
        }}
        onClick={handleClickNewCampaign}
      >
        <RocketLaunchIcon sx={{ mr: 1 }} />
        New campaign
      </Fab>

      <Typography
        variant="h5"
        sx={{
          color: '#eb9b10',
          fontFamily: 'Luckiest Guy, serif',
          mb: 10,
          mt: 15,
          ml: 2,
          mr: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        Your Account
      </Typography>

      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          p: 3, 
          borderRadius: 2, 
          boxShadow: 3, 
          backgroundColor: 'white',
          maxWidth: '800px',
          margin: '0 auto' // Center horizontally
        }}
      >

      <Paper elevation={3} sx={{ p: 2, mb: 2, width: '100%', maxWidth: '600px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{ mb: 1 }}>Number of Brands {`(${numberOfBrands}/${subscriptionLimits.numberOfBrands})`}</Typography>
          <LinearProgress 
            variant="determinate" 
            value={(numberOfBrands / subscriptionLimits.numberOfBrands) * 100} 
            sx={{ 
              height: 10, 
              borderRadius: 5, 
              backgroundColor: '#d3d3d3', 
              '& .MuiLinearProgress-bar': { backgroundColor: '#34deeb' }
            }} 
          />
        </Box>
        {subscriptionLimits.numberOfBrands > 1 && (
          <Button 
            variant="outlined" 
            onClick={upgradeBrands}
            sx={{ ml: 2 }}
          >
            Upgrade Brands
          </Button>
        )}
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 2, width: '100%', maxWidth: '600px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{ mb: 1 }}>Number of Active Campaigns {`(${numberOfActiveCampaigns}/${subscriptionLimits.numberOfActiveCampaigns})`}</Typography>
          <LinearProgress 
            variant="determinate" 
            value={(numberOfActiveCampaigns / subscriptionLimits.numberOfActiveCampaigns) * 100} 
            sx={{ 
              height: 10, 
              borderRadius: 5, 
              backgroundColor: '#d3d3d3', 
              '& .MuiLinearProgress-bar': { backgroundColor: '#e6128e' }
            }} 
          />
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 2, width: '100%', maxWidth: '600px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h6" sx={{ mb: 1 }}>Monthly Game Sessions {`(${numberOfMonthlyGameSessions}/${subscriptionLimits.numberOfMonthlyGameSessions})`}</Typography>
          <LinearProgress 
            variant="determinate" 
            value={(numberOfMonthlyGameSessions / subscriptionLimits.numberOfMonthlyGameSessions) * 100} 
            sx={{ 
              height: 10, 
              borderRadius: 5, 
              backgroundColor: '#d3d3d3', 
              '& .MuiLinearProgress-bar': { backgroundColor: '#6a1b9a' }
            }} 
          />
        </Box>
        {subscriptionLimits.numberOfMonthlyGameSessions > 2500 && (
          <Button 
            variant="outlined" 
            onClick={upgradeSessions}
            sx={{ ml: 2 }}
          >
            Upgrade Sessions
          </Button>
        )}
      </Paper>

      {showUpgrade ? (
        <Button 
          variant="contained" 
          onClick={handleUpgradePlan}
          sx={{ 
            mb: 2, 
            mt: 5, 
            backgroundColor: '#ff9800', 
            borderRadius: '20px',
            '&:hover': {
              backgroundColor: '#fb8c00'
            }
          }}
        >
          Upgrade to Premium
        </Button>
      ) : (
        <Button 
          variant="contained" 
          onClick={handleChangePlan} // Define handleChangePlan function similarly to handleUpgradePlan
          sx={{ 
            mb: 10, 
            mt: 5, 
            backgroundColor: '#34deeb', 
            borderRadius: '20px',
            '&:hover': {
              backgroundColor: '#32c6d6'
            }
          }}
        >
          Change Plan
        </Button>
      )}






      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Change API Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
              Are you sure you want to generate a new API key? This will delete the current API key and create a new one.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmChangeApiKey} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Account;
