import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import UpBar from './UpBar';
import CampaignStatisticsCard from './CampaignStatisticsCard';
import './styles.css'; // Ensure this is only imported once
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
const pinkColor = "#cd2bff";
const buttonStyle = {
  background: pinkColor,
  padding: '5px 40px',
  width: '100%',
  height: '60px'
};
const textStyles = {
  fontFamily: 'Quicksand, serif'
};
const numberOfCards = 10;

const actions = [
    { icon: <FileCopyIcon />, name: 'Copy' },
    { icon: <SaveIcon />, name: 'Save' },
    { icon: <PrintIcon />, name: 'Print' },
    { icon: <ShareIcon />, name: 'Share' },
  ];

  
const CompanyHome = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const auth = getAuth(); // Define auth here

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const [content, setContent] = useState("");

  useEffect(() => {
    const fetchFirstName = () => {
      onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          const displayName = currentUser.displayName;
          const firstName = displayName ? displayName.split(' ')[0] : "User";
          setContent(`Hi ${firstName}`);
        } else {
          console.log("No user signed in.");
        }
      });
    };
    fetchFirstName();
  }, [user]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setContent("Your Workspace");
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleClickNewCampaign = () => {
    navigate('/NewCampaignEcommerce');  // Updated to navigate to 'newEcommerce' page
};

  const campaignCards = Array.from({ length: numberOfCards }, (_, index) => (
    <CampaignStatisticsCard key={index} />
  ));




  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  
  return (

    <Box sx={{
      width: "100%",
      overflow: 'hidden',
      backgroundColor: '#f5f9ff',
      backgroundImage: 'url("https://GamifiedMarketing-PullZone.b-cdn.net/9307298.png")', // Set the background image here
      // backgroundSize: 'cover', // Ensure the background image covers the entire Box
      backgroundPosition: 'center' // Center the background image
    }}>



    <Box sx={{ width: '100%', position: 'fixed', zIndex: 999 }}>
      <UpBar />
    </Box>

        <Typography variant="h3" sx={{ fontSize: { xs: '35px', sm: '34px', md: '40px', lg: '60px' }, mt: 18,  ml: { xs: 2, sm: 2, md: 8, lg: 12 }, fontFamily: 'Luckiest Guy, serif', color: '#ecb910' }}>
          {content}
        </Typography>

        <Typography variant="h4" sx={{ fontSize: { xs: '20px', sm: '25px', md: '30px', lg: '40px' }, mt: 5, ml: { xs: 1, sm: 1, md: 7, lg: 10 } , color: "#4f4a4a", fontFamily: 'Quicksand, serif', fontWeight: 'bold' }}>
          <AutoFixHighIcon sx={{ fontSize: { xs: '20px', sm: '25px', md: '30px', lg: '40px' }, mr: 2, ml: 2 }} />
          Discover, specially for you.
        </Typography>

        <Fab 
    variant="extended" 
    sx={{
        position: 'fixed', 
        bottom: 20, 
        right: 100,
        fontFamily: 'Quicksand, serif',
        background: 'linear-gradient(45deg, #34deeb 50%, #e6128e 10%)',
        color: 'white',
        transition: 'transform 0.3s ease', // Smooth transition for transform changes
        '&:hover': {
            transform: 'scale(1.2)' // Scales up the Fab by 10% on hover
        }
    }}
    onClick={handleClickNewCampaign}  // Ensure the click handler is attached
>
    <RocketLaunchIcon sx={{ mr: 1 }} />
    New campaign
</Fab>
        <Box sx={{ width: { md: '90%', lg: '90%' }, mx: 'auto', display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={2} sx={{ padding: 3, marginTop: 3 }}>
                {campaignCards.map((card, index) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={index}>
                    {card}
                </Grid>
                ))}
            </Grid>
        </Box>
    </Box>
  );
}

export default CompanyHome;
