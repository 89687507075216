import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './styles.css'; // Create this file and import it
import OnboardingUpload from './OnboardingUpload';
import { Card } from '@mui/material';
import Slider from '@mui/material/Slider';

const pinkColor = "#cd2bff";


const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'

  };

  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };


const Onboarding2Agency = () => {

    return (
<div style={{ display: 'flex', height: '100vh', margin: 0, padding: 0}}>


    <Box sx={{ width:  ['90%', '80%', '50%' ], margin: 'auto', textAlign: 'center', mt: 5, mb:5,alignItems: 'center'}}>



            <Typography variant="h3" sx={{ color: '#00baba', fontFamily: 'Quicksand, serif'}}>
                    Now we want to understand your portfolio's design
            </Typography>  

            <Typography variant="h6" sx={{ mb: 1, mt: 2, color: pinkColor,  fontFamily: 'Quicksand, serif'}}>
            It will help our AI create beautiful, custom-made designs for them. 
                </Typography> 

                <Typography variant="h8" sx={{ mb: 4, color: '#a5adad', fontFamily: 'Quicksand, serif'}}>
                     2 minutes on Ave for each 
            </Typography>

            <Typography variant="h4" sx={{ mb: 1, mt: 2, color: '#007585',  fontFamily: 'Quicksand, serif'}}>
                Big portfolio? Don't worry. Just skip and fill it later.  
                </Typography> 

                <Card style={{ marginTop: '50px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h8" sx={{ color: '#a5adad', fontFamily: 'Quicksand, serif', textAlign: 'center', marginBottom: '4' }}>
                        Company 1
                    </Typography>

                    <div style={{ marginTop: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <OnboardingUpload Text="Logo" />
                        <OnboardingUpload Text="Art" />

                        <Box sx={{ width: 300, mt:3 }}>
                            <Typography variant="h8" sx={{ color: '#a5adad', fontFamily: 'Quicksand, serif', textAlign: 'center', marginBottom: '4' }}>
                                    Is your brand colorful?
                            </Typography>

                            <Slider defaultValue={30} step={10} marks min={10} max={110} />
                        </Box>
                        
                    </div>
                </Card>


            <Button
                variant="contained"
                sx={{
                    width: "80%",
                    fontSize: "0.8rem",
                    backgroundColor: '#007585',
                    fontFamily: 'Quicksand, serif',
                    marginTop: 5 // Add margin-top of 5
                }}
                >
                Add another Company
                </Button>


            <Typography variant="h5" sx={{ color: '#219c9a', fontFamily: 'Quicksand, serif', mt: 10 }}>
                    You will always be able to update your Design profile later on
                </Typography>
    </Box>



        </div>
    );
}

export default Onboarding2Agency;
