import React, { createContext, useContext, useState, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue } from 'firebase/database';

const BrandContext = createContext();

export const useBrand = () => useContext(BrandContext);

export const BrandProvider = ({ children }) => {
  const [currentBrand, setCurrentBrand] = useState(null);
  const [brands, setBrands] = useState([]);

  const fetchBrands = useCallback((user) => {
    const db = getDatabase();
    const brandsRef = ref(db, `users/${user.uid}/brands`);
    onValue(brandsRef, (snapshot) => {
      const brandsData = snapshot.val();
      const brandsList = brandsData ? Object.keys(brandsData).map(key => ({ id: key, ...brandsData[key] })) : [];
      setBrands(brandsList);
      if (brandsList.length > 0) {
        const savedBrandId = localStorage.getItem('currentBrandId');
        const savedBrand = brandsList.find(brand => brand.id === savedBrandId);
        setCurrentBrand(savedBrand || brandsList[0]);
      }
    });
  }, []);

  return (
    <BrandContext.Provider value={{ currentBrand, setCurrentBrand, brands, fetchBrands }}>
      {children}
    </BrandContext.Provider>
  );
};
