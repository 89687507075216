import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, off, update } from 'firebase/database'; // Import 'update' for updating database
import './styles.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField'; // Import for the input field
import UpBar from './UpBar';
import SecondUpBar from './SecondUpBar';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { useBrand } from '../contexts/BrandContext'; // Correct import path
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for loading indicator
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const DesignProfile = () => {

    const { currentBrand, setCurrentBrand, brands, fetchBrands } = useBrand();

    console.log("currentBrand ", currentBrand );

    const navigate = useNavigate(); // Correct use of useNavigate

    const [brandSummary, setBrandSummary] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');

    const [currentUser, setCurrentUser] = useState(null);
    const [brandName, setBrandName] = useState('brand`s name');
    const [websiteLink, setWebsiteLink] = useState('www.brandWebsite.com');
    const [user, setUser] = React.useState(null); // State to hold the user object
    const fileInputRef = React.createRef();
    const [removeBackground, setRemoveBackground] = useState(false);
    const [isHovering, setIsHovering] = useState(false); // State to handle hover
    const [isLoading, setIsLoading] = useState(false); // State to handle loading
    const [openDialog, setOpenDialog] = useState(false); // State to handle dialog


    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user);
          } else {
            // No user is signed in, redirecting
            navigate('/login'); // Redirect to login or another appropriate route
          }
        });
        return () => unsubscribe(); // Cleanup subscription on unmount
      }, [navigate]);
    

      useEffect(() => {
        setPreviewUrl(''); // Reset previewUrl whenever currentBrand.id changes
        setImageUrl(`https://GamifiedMarketing-PullZone.b-cdn.net/brands/${currentBrand?.id}/logo.png?timestamp=${new Date().getTime()}`);

    }, [currentBrand?.id]);
    
      
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (!currentUser || !currentBrand) return;

        const db = getDatabase();
        const userRef = ref(db, `/users/${currentUser.uid}/brands/${currentBrand?.id}`);
        
        const unsubscribeSummary = onValue(ref(db, `/users/${currentUser.uid}/brands/${currentBrand?.id}/BrandSummery`), (snapshot) => {
            const data = snapshot.val();
            // Ensure BrandSummery is a string before setting it
            setBrandSummary(typeof data === 'string' ? data : 'Brand summary is not available.');
        });

        const unsubscribeBrandName = onValue(ref(db, `/users/${currentUser.uid}/brands/${currentBrand?.id}/BrandName`), (snapshot) => {
            const data = snapshot.val();
            if (data) setBrandName(data);
        });

        const unsubscribeWebsiteLink = onValue(ref(db, `/users/${currentUser.uid}/brands/${currentBrand?.id}/websiteLink`), (snapshot) => {
            const data = snapshot.val();
            if (data) setWebsiteLink(data);
        });

        setImageUrl(`https://GamifiedMarketing-PullZone.b-cdn.net/brands/${currentBrand?.id}/logo.png?timestamp=${new Date().getTime()}`);

        return () => {
            off(ref(db, `/users/${currentUser.uid}/brands/${currentBrand?.id}/BrandSummery`), 'value', unsubscribeSummary);
            off(ref(db, `/users/${currentUser.uid}/brands/${currentBrand?.id}/BrandName`), 'value', unsubscribeBrandName);
            off(ref(db, `/users/${currentUser.uid}/brands/${currentBrand?.id}/websiteLink`), 'value', unsubscribeWebsiteLink);
        };

    }, [currentUser, currentBrand ]);

    const handleInputChange = (event, setterFunction, databasePath) => {
        const newValue = event.target.value;
        setterFunction(newValue);
    
        if (!currentUser) return; // Ensure there's a logged-in user
    
        const db = getDatabase();
        const updates = {};
        updates[databasePath] = newValue; // Wrap the new value in an object
        update(ref(db, `/users/${currentUser.uid}/brands/${currentBrand?.id}`), updates); // Pass the object to the update function
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewUrl(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpload = async () => {
        setIsLoading(true); // Start loading

        // Firebase storage upload logic here
        console.log("Inside handleUpload");
        setImageUrl(previewUrl);

        const file = fileInputRef.current.files[0]; // Get the file from the file input
        if (!file) {
            console.log("No file selected.");
            setIsLoading(false); // Stop loading
            return;
        }
    
        const formData = new FormData();
        formData.append('userUid', user.uid); // Convert user object to JSON string
        formData.append('currentBrand', currentBrand?.id);
        formData.append('removeBackground', removeBackground);
        formData.append('logo', file); // Append the file to the form data

        try {
          const response = await fetch('https://gamifiedmarketing.ai:3000', {
            method: 'POST',
            headers: {
              'react-app': 'changeLogoDesignProfile' // Custom header
            },
            body: formData
          });
          const responseData = await response.json();
          if (response.ok) {
            fetchBrands(user); // Fetch the updated brands after the logo is uploaded
            setImageUrl(`https://GamifiedMarketing-PullZone.b-cdn.net/brands/${currentBrand?.id}/logo.png?timestamp=${new Date().getTime()}`);
            setIsLoading(false); // Stop loading
            setPreviewUrl(''); // Clear the preview URL

            console.log('Campaign generated responseData:', responseData);
            console.log('Campaign generated response:', response);
          } else {
            setIsLoading(false); // Stop loading
            throw new Error(responseData.message || 'Failed to generate campaign');
          }
        } catch (error) {
          setIsLoading(false); // Stop loading
          console.error('Error generating campaign:', error);
        };
    }

    useEffect(() => {
        if (!currentBrand) return;

        setPreviewUrl(''); // Reset previewUrl whenever currentBrand.id changes
        setImageUrl(`https://GamifiedMarketing-PullZone.b-cdn.net/brands/${currentBrand?.id}/logo.png?timestamp=${new Date().getTime()}`);
    }, [currentBrand?.id]);

    useEffect(() => {
        if (brandName === "undefined brand name" || brandSummary === "had a problem with summering the brand") {
            setOpenDialog(true);
        }
    }, [brandName, brandSummary]);

    return (
        <Box sx={{
            width: "100%",
            overflow: 'hidden',
            backgroundColor: '#f5f9ff',
            backgroundImage: 'url("https://GamifiedMarketing-PullZone.b-cdn.net/9307298.png")', // Set the background image here
            // backgroundSize: 'cover', // Ensure the background image covers the entire Box
            backgroundPosition: 'center' // Center the background image
          }}>
              <Box sx={{ width: '100%', position: 'fixed', zIndex: '999' }}>
        <UpBar />
      </Box>

            <Box sx={{width:"100%", mt:20}}>
                <Typography variant="h4" sx={{  color: '#d90d7d', fontFamily: 'Luckiest Guy, serif', mb: 5, mt:5,  ml:2, mr:2, display: 'flex', flexDirection: 'column', alignItems: 'center',  textAlign: 'center',  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)', fontSize: { xs: '40px', sm: "40px", md: "50px" },}}>
                    Your brand's profile
                </Typography>

                <Typography
                    variant="h5"
                    sx={{
                        color: '#eb9b10',
                        fontFamily: 'Luckiest Guy, serif',
                        mb: 10,
                        mt: 3,
                        ml: 7,
                        mr: 7,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // This ensures the content is centered horizontally
                        justifyContent: 'center', // Centers vertically in the flex container (if needed)
                        textAlign: 'center' // Centers the text itself
                    }}
                    >
                    our AI uses this information to create unique campaigns specially for you
                    </Typography>

                <Box sx={{ width: "100%", mb: 10, display: 'flex', justifyContent: 'center' }}>
                    <Card sx={{ maxWidth: 445 }}>
                        <CardActionArea>

                            <Box 
                                onMouseEnter={() => setIsHovering(true)}
                                onMouseLeave={() => setIsHovering(false)}
                                sx={{ position: 'relative' }}
                            >
                                <CardMedia
                                    component="img"
                                    height="70%"
                                    image={previewUrl || imageUrl}
                                    alt="brand logo"
                                />
                                {isHovering && !isLoading && (
                                    <Button 
                                            variant="contained" 
                                            onClick={() => fileInputRef.current.click()} 
                                            sx={{ 
                                                position: 'absolute', 
                                                top: '50%', 
                                                left: '50%', 
                                                transform: 'translate(-50%, -50%)',  
                                                fontFamily: "'Luckiest Guy', serif",  
                                                backgroundColor: '#34d5eb', // Updated base background color
                                                padding: '10px 20px',  
                                                transition: 'transform 0.3s ease-in-out',
                                                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',

                                                '&:hover': {
                                                backgroundColor: '#345eb', // Darken background on hover
                                                },
                                            }}
                                        >
                                            Change Logo
                                    </Button>

                                )}
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                    onClick={(event) => { event.target.value = null; }} // to reset the file input
                                />
                            </Box>
                            {isLoading && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <CircularProgress />
                                </Box>
                            )}
                            {previewUrl && !isLoading && (
                                <>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <FormControlLabel
                                            control={<Checkbox checked={removeBackground} onChange={(e) => setRemoveBackground(e.target.checked)} />}
                                            label="Remove Background"
                                        />
                                        <Button onClick={handleUpload} variant="contained" color="primary">
                                            Upload The New Logo
                                        </Button>
                                    </Box>
                                </>
                            )}
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{ color: '#d90d7d', mb: 1, fontFamily: 'Luckiest Guy, serif' }} >
                                    {brandName}
                                </Typography>
                                <TextField
                                    label="Edit Brand Name"
                                    variant="outlined"
                                    value={brandName}
                                    onChange={(e) => handleInputChange(e, setBrandName, '/BrandName')}
                                    sx={{ mb: 6, mt: 2, width: '100%' }}
                                />
                                <Typography variant="body2" color="text.secondary" sx={{ color: '#d90d7d', fontWeight: 'bold', fontSize: '1.25rem' }}>
                                    {websiteLink}
                                </Typography>
                                <TextField
                                    label="Edit Website Link"
                                    variant="outlined"
                                    value={websiteLink}
                                    onChange={(e) => handleInputChange(e, setWebsiteLink, '/websiteLink')}
                                    sx={{ mb: 6, mt: 2, width: '100%' }}
                                />
                                <Typography variant="body2" color="text.secondary" sx={{ color: '#d90d7d', fontWeight: 'bold', fontSize: '1.25rem' }}>
                                    {brandSummary || "Loading brand summary..."}
                                </Typography>
                                <TextField
                                    label="Edit Brand Summary"
                                    variant="outlined"
                                    value={brandSummary}
                                    multiline
                                    rows={4}
                                    onChange={(e) => handleInputChange(e, setBrandSummary, '/BrandSummery')}
                                    sx={{ mb: 1, mt: 2, width: '100%' }}
                                />
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Box>

                <Typography
                    variant="h5"
                    sx={{
                        color: '#eb9b10',
                        fontFamily: 'Luckiest Guy, serif',
                        mb: 10,
                        mt: 3,
                        ml: 2,
                        mr: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // This ensures the content is centered horizontally
                        justifyContent: 'center', // Centers vertically in the flex container (if needed)
                        textAlign: 'center' // Centers the text itself
                    }}
                    >
                    edit it if you see something wrong
                    </Typography>
            </Box>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle sx={{ fontFamily: 'Luckiest Guy, serif', color: '#eb9b10' }}>Our AI had some problems regarding this information</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ fontFamily: 'Luckiest Guy, serif', color: '#eb9b10' }}>
                        Please fill this information.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Brand Name"
                        type="text"
                        fullWidth
                        value={brandName}
                        onChange={(e) => handleInputChange(e, setBrandName, '/BrandName')}
                    />
                    <TextField
                        margin="dense"
                        label="Brand Summary"
                        type="text"
                        fullWidth
                        value={brandSummary}
                        onChange={(e) => handleInputChange(e, setBrandSummary, '/BrandSummery')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default DesignProfile;
