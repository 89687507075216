import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoginCard from './LoginCard';
import SignUp from './SignUp';
import  useState  from 'react';
import RightSideLogIn from './RightSideLogIn';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import UpBar from './UpBar'
import SecondUpBar from './SecondUpBar'
import LeftNavBar from './LeftNavBar'
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';


import Chip from '@mui/material/Chip';
import BoltIcon from '@mui/icons-material/Bolt';

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import TuneIcon from '@mui/icons-material/Tune';

import './styles.css'; // Create this file and import it
import CompanyCard from './CompanyCard';
import { AppBar, Card } from '@mui/material';
import { pink } from '@mui/material/colors';

const pinkColor = "#cd2bff";
const pinkGradient = "linear-gradient(to right, #ff66a3, #8a2be2)";


const buttonStyle = {
    background: pinkColor,
    padding: '5px 40px', // Adjust the padding as needed (top/bottom 10px, left/right 20px)
    width: '100%', // Set a fixed width for the buttons
    height: '60px'

  };

  const textStyles = {
    fontFamily: 'Quicksand, serif',
  };




const DiscoverCard = () => {

    return (

        <div style={{ display: 'flex', height: '100%', padding: 0}}>

            
            <Box sx={{width:"100%", mr:4}}>
                 
                    <Card sx={{width:"700px", height:"500px", ml:2, mt:3,borderRadius:4}}>



                            <Grid container>
                                
                                <Grid item sx ={{width:"50%", height:"100%"}}>
                                <Box>
                                    <iframe
                                        title="Your iFrame Title"
                                        src="https://score-app-basic.web.app/"
                                        width="100%"
                                        height="500x"
                                        style={{ margin: 0, padding: 0, display: 'block', borderRadius: '20px' }} // Set margin, padding, display, and border radius
                                        allowFullScreen
                                    ></iframe>
                                </Box>
                                </Grid>


                                <Grid item sx ={{width:"50%"}}>
                                <Tooltip title="Our AI forecasts your campaign success." placement="top">

                                <div style={{ display: 'flex', justifyContent: 'center'}}>
                                        <Chip
                                            label="98%"
                                            color="success"
                                            avatar={
                                            <Avatar sx={{ bgcolor: pinkColor }}>
                                                <BoltIcon />
                                            </Avatar>
                                            }
                                            style={{ fontFamily: 'Quicksand, serif' }}
                                        />
                                </div>
                                </Tooltip>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            height: '100vh', // Set height as needed
                                            mt: 2
                                        }}
                                        >
                                        <Typography
                                            variant="h4"
                                            sx={{
                                            color: pinkColor,
                                            fontFamily: 'Quicksand, serif',
                                            textAlign: 'center',
                                            marginBottom: 0,
                                            }}
                                        >
                                            Catch the Pizza
                                        </Typography>
                                        
                                        <Typography
                                            variant="h6"
                                            sx={{
                                            color: pinkColor,
                                            fontFamily: 'Quicksand, serif',
                                            textAlign: 'center',
                                            marginBottom: 1,
                                            }}
                                        >
                                            Catch the Pizza
                                        </Typography>

                                        <Typography
                                            variant="h7"
                                            sx={{
                                            color: "#2dcfc9",
                                            fontFamily: 'Quicksand, serif',
                                            textAlign: 'center',
                                            marginBottom: 4,
                                            }}
                                        >
                                            11/03/24 - 12/04/24
                                        </Typography>

                                        <Typography
                                            variant="h7"
                                            sx={{
                                            color: pinkColor,
                                            fontFamily: 'Quicksand, serif',
                                            textAlign: 'center',
                                            marginBottom: 4,
                                            }}
                                        >
                                                This is why you should upload it!
                                        </Typography>



                                    </Box>
                                    


                                </Grid>
                            </Grid>
                    </Card>


                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                            <Fab variant="extended" size="small" sx={{ color: '#ffffff', marginLeft: 2, fontFamily: 'Luckiest Guy, serif', fontSize: { xs: '10px', sm: '10px', md: '16px', lg: '22px' }, background: 'linear-gradient(to bottom, #ecb910, #f36a1b)' , padding:2, textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)'}}>
                                Customize & Launch
                                <TuneIcon sx={{ ml: 2 }} />
                            </Fab>
                        </Box>
                    </Box>



                    

            </Box>


        </div>
    );
}

export default DiscoverCard;
