import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'; // Import the icon
import IconButton from '@mui/material/IconButton'; // Ensure IconButton is imported
import Tooltip from '@mui/material/Tooltip';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue } from 'firebase/database';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser'; // Import the OpenInBrowser icon

const CampaignStatisticsCard = ({ urlString }) => {
    const [user, setUser] = useState(null);
    const [campaignData, setCampaignData] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); // State to manage Dialog open/close

    useEffect(() => {
        const auth = getAuth();
        const unsubscribeAuth = onAuthStateChanged(auth, user => {
            if (user) {
                setUser(user);
            }
        });

        return () => {
            unsubscribeAuth();
        };
    }, []);

    useEffect(() => {
        if (user && urlString) {
            const db = getDatabase();
            try {
                const url = new URL(urlString);
                const urlParams = new URLSearchParams(url.search);
                const id = urlParams.get('id');
                const version = urlParams.get('v');
                const campaignCode = url.pathname.replace(/^\/|\/$/g, '');

                const campaignRef = ref(db, `users/${user.uid}/brands/${id}/liveCampaigns/Ecommerce/${version}/NumOfVisitors`);
                const unsubscribe = onValue(campaignRef, snapshot => {
                    const data = snapshot.val();
                    setCampaignData(data);
                }, error => {
                    console.error("Error fetching data: ", error);
                });

                return () => {
                    unsubscribe();
                };
            } catch (error) {
                console.error("Invalid URL:", urlString, error);
            }
        }
    }, [urlString, user]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const openInNewTab = () => {
        window.open("https://score-app-basic.web.app/");
    };
    return (
        <div style={{ display: 'flex', margin: 0, padding: 0 }}>
            <Box sx={{ width: "100%", }}>
                <Card sx={{ width: '100%', ml: "auto", mr: "auto", height: "auto", mt: 3, borderRadius: '20px', position: 'relative' }}>
                <Tooltip title="Open Full Screen">
                <Button onClick={handleOpenDialog} sx={{ position: 'absolute', top: 0, left: 20, zIndex: 1000, color: 'white' }}>
                    <OpenInFullIcon />
                </Button>
            </Tooltip>
            <Tooltip title="Open in New Tab">
                <IconButton onClick={openInNewTab} sx={{ position: 'absolute', top: 0, left: 0, zIndex: 1000, color: 'white' }}>
                    <OpenInBrowserIcon />
                </IconButton>
            </Tooltip>

                    <Dialog
    open={openDialog}
    onClose={handleCloseDialog}
    maxWidth="md"
    fullWidth
    sx={{
        '& .MuiPaper-root': {
            width: '100%',
            height: 'auto',
            maxWidth: '95vw',
            maxHeight: '95vh',
            m: 0,
            borderRadius: '25px',
            overflow: 'hidden',

            '@media (min-width: 1300px)': {
                width: '800px',
                height: '1500px',
                m: 0
            },

            '@media (max-width: 1300px)': {
                width: '60vw',
                height: '85vh',
                m: 0
            },

            '@media (max-width: 1000px)': {
                width: '70vw',
                height: '95vh',
                m: 0
            },
            '@media (max-width: 600px)': {
                width: '95vw',
                height: '95vh',
                m: 0
            },

        }
    }}
>
    <DialogContent sx={{ padding: 0, overflow: 'hidden', position: 'relative' }}> {/* Apply position relative for absolute positioning of the icon */}
        <IconButton
            onClick={handleCloseDialog}
            sx={{
                position: 'absolute', // Positioning the button
                top: 8, // Distance from the top
                right: 8, // Distance from the right
                color: 'common.white' // Button color
            }}
        >
            <FullscreenExitIcon />
        </IconButton>
        <iframe
            title="Campaign Statistics"
            src="https://score-app-basic.web.app/"
            style={{
                width: '100%',
                height: '100%',
                borderRadius: '25px',
                border: 'none',
                margin: 0,
                padding: 0,
                overflow: 'hidden'
            }}
            allowFullScreen
        ></iframe>
    </DialogContent>
</Dialog>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                borderRadius: '25px',
                                margin: 0,
                                padding: 0,
                                display: 'block',
                                height: '100%',
                                position: 'relative'
                            }}
                        >
                            <iframe
                                title="Campaign Statistics"
                                src="https://score-app-basic.web.app/"
                                width="100%"
                                height="600px"
                                style={{ borderRadius: '25px' }}
                                allowFullScreen
                            ></iframe>
                        </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '260px' }}>
                                <Typography variant="h4">Papa Johns</Typography>
                                <Typography variant="h6">Catch the Pizza</Typography>
                                <Typography variant="subtitle1">11/03/24 - 12/04/24</Typography>
                                <Typography variant="subtitle1">Explanation</Typography>
                                <Box sx={{ width: '90%', height: '25%' }}>
                                    <Box sx={{ padding: 1, backgroundColor: '#e6ffff', borderRadius: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                        <Typography variant="subtitle1">Total</Typography>
                                        <Typography variant="subtitle1" ml={1}>{campaignData || 'Loading...'}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </div>
    );
};

export default CampaignStatisticsCard;
